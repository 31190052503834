import React from "react";
import AvatarPage from "../../../../../../components/aiAvatar/main/pages/AvatarPage/AvatarPage";
import ASSETS from "../../../../../../assets/Assets";
import { useDispatch, useSelector } from "react-redux";
import { setRecordVideoButton } from "../../../../../../store/avatar/avatarSlice";
import {
  setIsSpeech,
  setGreetingsIsComplete,
} from "../../../../../../store/avatar/avatarSlice";

const containerStyleBasic = {
  borderRadius: "24px",
  border: "1px solid rgba(40, 40, 96, 0.15)",
  background: "#fff",
  boxShadow: "0px 8px 16px 0px rgba(41, 40, 45, 0.02)",
  height: "100%",
};

const AvatarPanel = ({ assessmentId, setPanel }) => {
  const dispatch = useDispatch();
  const recordVideoButton = useSelector(
    (store) => store.avatar.recordVideoButton
  );

  return (
    <>
      <div
        style={{
          ...containerStyleBasic,
          height: "100%",
          width: "100%",
          padding: "20px 30px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              borderRadius: "50%",
              border: "1px solid #ABB2F2",
              background: "#C9CEFA",
              height: "35px",
              width: "35px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className={`${recordVideoButton ? "disabled" : ""}`}
            onClick={() => {
              setPanel(0);
              dispatch(setIsSpeech(false));
              dispatch(setRecordVideoButton(false));
              dispatch(setGreetingsIsComplete(false));
            }}
          >
            <img src={ASSETS.backArrow} alt="back" />
          </div>
          <div>Ai Check-in Status</div>
        </div>
        <div
          style={{
            display: "flex",
            height: "calc(100% - 50px)",
            width: "100%",
            gap: "20px",
            flexDirection: "column",
          }}
        >
          <AvatarPage assessmentId={assessmentId} />
        </div>
      </div>
    </>
  );
};

export default AvatarPanel;
