/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useRef, Suspense, useEffect } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import { PerspectiveCamera, OrbitControls } from "@react-three/drei";
import AvatarScene from "../../scene/AvatarScene";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import RecordRTC from "recordrtc";
import background from "../../../assets/background.png";

const CustomCamera = React.memo(
  ({ cameraPosition, cameraRotation, disableOrbitControl, ...props }) => {
    const virtualCamera = useRef();
    return (
      <>
        {/* <PerspectiveCamera
        name="FBO Camera"
        ref={virtualCamera}
        position={[0.0, 1.0, 5.0]}
        fov={30}
        aspect={window.innerWidth / window.innerHeight}
        near={0.1}
        far={20}
      /> */}

        <PerspectiveCamera
          makeDefault
          position={cameraPosition}
          fov={30}
          aspect={window.innerWidth / window.innerHeight}
          near={0.1}
          far={60}
          rotation={cameraRotation}
        />

        {disableOrbitControl && (
          <OrbitControls
            camera={virtualCamera.current}
            {...props}
            screenSpacePanning={true}
            target={[0.0, 1.0, 0.0]}
          />
        )}
      </>
    );
  }
);

const Environment = () => {
  // const gltf = useLoader(GLTFLoader, "/public/assets/environment/bedroom.glb");
  // return <primitive receiveShadow castShadow object={gltf.scene} position={[-0.5, -0.015, 2]} scale={[0.01, 0.01, 0.01]}/>;

  // const gltf = useLoader(GLTFLoader, "/assets/environment/white-room1.glb");
  // return <primitive receiveShadow castShadow object={gltf.scene} position={[0, -0.015, 0]} />;

  // const gltf = useLoader(GLTFLoader, "/public/assets/environment/studio_room.glb");
  // return <primitive receiveShadow castShadow object={gltf.scene} position={[0, -0.015, -4]} />;

  // const gltf = useLoader(GLTFLoader, "/public/assets/environment/waiting_room.glb");
  // return <primitive receiveShadow castShadow object={gltf.scene} position={[-.5, -0.035, -2]} scale={[0.03, 0.03, 0.03]} rotation={[0, -1.57, 0]} />;

  // const gltf = useLoader(GLTFLoader, "/public/assets/environment/room.glb");
  // return (
  //   <primitive
  //     receiveShadow
  //     castShadow
  //     object={gltf.scene}
  //     position={[0, 0.515, -4]}
  //     scale={[0.3, 0.3, 0.3]}
  //   />
  // );

  const gltf = useLoader(GLTFLoader, "/assets/environment/sitting_room.glb");
  return (
    <primitive
      receiveShadow
      castShadow
      object={gltf.scene}
      position={[0, -0.035, 0]}
      rotation={[0, -1, 0]}
      scale={[0.9, 0.9, 0.9]}
    />
  );
};

const CustomEnvironment = () => {
  return (
    <mesh receiveShadow position={[0, -0.1, 0]}>
      <boxGeometry args={[8, 0.1, 8]} />
      <meshStandardMaterial color="#78b399" />
    </mesh>
  );
};

const BackgroundImage = () => {
  return (
    <img
      src={background}
      alt="background"
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
      }}
    />
  );
};

export default function DisplayAvatar({
  style,
  animation,
  gridHelper,
  avatarPosition,
  talk,
  cameraPosition,
  followMouse,
  cameraRotation,
  disableOrbitControl,
  rotateModel,
  model,
}) {
  const meshRef = useRef();
  const threeCanvasRef = useRef(null);

  const handleScreenRecord = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function (audioStream) {
        const threeCanvas = threeCanvasRef.current;

        // var canvas = document.getElementById("2d_canv");
        var canvasStream = threeCanvas.captureStream();

        var finalStream = new MediaStream();
        RecordRTC.getTracks(audioStream, "audio").forEach(function (track) {
          finalStream.addTrack(track);
        });
        RecordRTC.getTracks(canvasStream, "video").forEach(function (track) {
          finalStream.addTrack(track);
        });

        var recorder = RecordRTC(finalStream, {
          type: "video",
        });

        recorder.startRecording();

        var stop = false;

        (function looper() {
          if (stop) {
            recorder.stopRecording(function () {
              var blob = recorder.getBlob();
              document.body.innerHTML =
                '<video controls src="' +
                URL.createObjectURL(blob) +
                '" autoplay loop></video>';

              audioStream.stop();
              canvasStream.stop();
            });
            return;
          }
          setTimeout(looper, 100);
        })();

        var seconds = 15;
        var interval = setInterval(function () {
          seconds--;
          if (document.querySelector("h1")) {
            document.querySelector("h1").innerHTML =
              seconds + " seconds remaining.";
          }
        }, 1000);

        setTimeout(function () {
          clearTimeout(interval);
          stop = true;
        }, seconds * 1000);
      });
  };

  return (
    <>
      <BackgroundImage />
      <Canvas
        ref={threeCanvasRef}
        id="canvas"
        style={style}
        gl={{ logarithmicDepthBuffer: true }}
        shadows
        dpr={[1, 2]}
      >
        <CustomCamera
          cameraPosition={cameraPosition}
          cameraRotation={cameraRotation}
          disableOrbitControl={disableOrbitControl}
        />
        <directionalLight
          castShadow
          color="#fdefd0"
          position={[1.1, 1.5, 1.5]}
          intensity={0.55}
          // intensity={1}
        />
        {/* <directionalLight
          castShadow
          color="#fdefd0"
          position={[-3, 4, 3]}
          intensity={1}
        /> */}

        {/* <spotLight position={[0, 0, 0]} intensity={0.5}/> */}
        {/* <pointLight position={[0, 0, 0]} intensity={0.5}/> */}
        <ambientLight intensity={0.8} />

        <group ref={meshRef}>
          <Suspense fallback={null}>
            <AvatarScene
              followMouse={followMouse}
              animation={animation}
              position={avatarPosition}
              talk={talk}
              rotateModel={rotateModel}
              model={model}
            />
            {/* <Environment /> */}
            {/* <CustomEnvironment /> */}
          </Suspense>
        </group>
        {gridHelper ? <gridHelper /> : ""}
      </Canvas>
    </>
  );
}
