import { createSlice } from "@reduxjs/toolkit";

const activitySlice = createSlice({
  name: "activities",
  initialState: {
    activities: [],
  },
  reducers: {
    setActivities: (state, action) => {
      return {
        activities: action.payload,
      };
    },
  },
});

export const { setActivities } = activitySlice.actions;

export default activitySlice.reducer;
