export const generateChatTitle = (message, trimLen) => {
  if (message.length <= trimLen) {
    return message;
  } else {
    return message.slice(0, trimLen) + "...";
  }
};

export const detectMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}