let myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

let requestOptions = {
  method: "POST",
  headers: myHeaders,
  redirect: "follow",
};
const fetchInfo = async (fetchURL, reqOpt = requestOptions) => {
  let resData = await fetch(fetchURL, reqOpt)
    .then(
      async (response) => {
        const apiResponse = {};
        apiResponse.status = response.status;

        if (response.status === 204) return "loggedout";
        else if (response.status === 401) return "loggedout";

        apiResponse.data = await response.json();

        return apiResponse;

        // else if (response.status !== 200) return response.json();
        // return response.text();
      },
      (error) => {
        return "error";
      }
    )
    .then(
      (result) => {
        return result;
      },
      (error) => {
        return "error";
      }
    );
  return resData;
};

export const Login = async (body) => {
  let req = { ...requestOptions };
  req.body = body;

  let fetchReq =
    "https://api-mindwren.imbesideyou.world/v1/auth/login";

  return await fetchInfo(fetchReq, req);
};

export const Register = async (body) => {
  let req = { ...requestOptions };
  req.body = body;

  let fetchReq =
    "https://api-mindwren.imbesideyou.world/v1/auth/register";

  return await fetchInfo(fetchReq, req);
};

export const Logout = async (body) => {
  let req = { ...requestOptions };
  req.body = body;

  let fetchReq =
    "https://api-mindwren.imbesideyou.world/v1/auth/logout";

  return await fetchInfo(fetchReq, req);
};

export const Chatgpt = async (body, jwt) => {
  let req = { ...requestOptions };

  const raw = JSON.stringify({
    content: body,
  });

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + jwt);

  req.body = raw;
  req.headers = headers;

  let fetchReq = "https://api-mindwren.imbesideyou.world/v1/chat";

  return await fetchInfo(fetchReq, req);
};

export const savePhq = async (body, jwt) => {
  let req = { ...requestOptions };

  const raw = JSON.stringify(body);

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + jwt);

  req.body = raw;
  req.headers = headers;

  let fetchReq = "https://api-mindwren.imbesideyou.world/v1/phq";

  return await fetchInfo(fetchReq, req);
};

export const getLatestPhq = async (body, jwt) => {
  let req = { ...requestOptions };
  req.method = "GET";

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + jwt);

  req.headers = headers;

  const userId = body.userId;
  let fetchReq =
    `https://` + "api-mindwren.imbesideyou.world" + `/v1/phq/latest/${userId}`;

  return await fetchInfo(fetchReq, req);
};

export const saveChatHistory = async (body, jwt) => {
  let req = { ...requestOptions };

  const raw = JSON.stringify(body);

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + jwt);

  req.body = raw;
  req.headers = headers;

  let fetchReq =
    "https://api-mindwren.imbesideyou.world/v1/chat/save-history";

  return await fetchInfo(fetchReq, req);
};

export const getChatHistoryOfUser = async (body, jwt) => {
  let req = { ...requestOptions };
  req.method = "GET";

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + jwt);

  req.headers = headers;

  const userId = body.userId;
  let fetchReq =
    `https://` +
    "api-mindwren.imbesideyou.world" +
    `/v1/chat/get-titles/${userId}`;

  return await fetchInfo(fetchReq, req);
};

export const generatePresignedUrl = async (body, jwt) => {
  let req = { ...requestOptions };

  const raw = JSON.stringify(body);

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + jwt);

  req.body = raw;
  req.headers = headers;

  let fetchReq =
    "https://api-mindwren.imbesideyou.world/v1/chat/generate-url";

  return await fetchInfo(fetchReq, req);
};

export const getAllSbtDetails = async (jwt) => {
  let req = { ...requestOptions };
  req.method = "GET";

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + jwt);

  req.headers = headers;

  let fetchReq =
    `https://` + "api-mindwren.imbesideyou.world" + `/v1/sbt/users/get-sbt-tx`;

  return await fetchInfo(fetchReq, req);
};

export const updateVideoFileName = async (body, jwt) => {
  let req = { ...requestOptions };
  req.method = "PUT";

  const raw = JSON.stringify(body);

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + jwt);

  req.body = raw;
  req.headers = headers;

  let fetchReq =
    "https://api-mindwren.imbesideyou.world/v1/chat/update-filename";

  return await fetchInfo(fetchReq, req);
};
