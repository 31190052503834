import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

export const createAssessmentAPI = async (
  assessmentId,
  answers,
  score,
  completedAt
) => {
  const baseURL = config.API_URL;
  const path = "/assessments";
  const body = {
    assessment_id: assessmentId,
    answers: answers,
    score: score,
    completed_at: completedAt,
  };

  return await axios.post(`${baseURL + path}`, body);
};

export const getSingleAssessmentAPI = async (assessmentId) => {
  const baseURL = config.API_URL;
  const path = "/assessments";
  const params = "?user_type=client" + "&assessment_id=" + assessmentId;

  return await axios.get(`${baseURL + path + params}`);
};

// Assessments and their details
export const getAllAssessmentAPI = async (therapyId) => {
  const baseURL = config.API_URL;
  const path = "/all_assessments";
  const params = "?therapy_id=" + therapyId;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.get(`${baseURL + path + params}`, {
    headers: {
      Authorization: idToken,
    },
  });
};
