import "./Blogs.css";

import { useSelector } from "react-redux";

function Blogs() {
  const blogs = useSelector((store) => store.blogs);

  return (
    <div className="client-blogs">
      <div className="blogs-row scroll-thin">
        <div className="blog-subheading">Blogs for You</div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: "2rem",
          }}
        >
          {blogs.map((blog, idx) => {
            return (
              <div className="client-blog" key={idx}>
                <div
                  className="client-blog-banner"
                  onClick={() => window.open(blog.link, "_blank")}
                >
                  <img src={blog.banner} alt="blog banner" />
                </div>
                <div className="client-blog-heading">{blog.title}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Blogs;
