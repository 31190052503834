import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

export const getLlmResponseForAvatar = async (
  transcript,
  aiAvatarSessionId
  ) => {

  console.log("aiAvatarSessionId", aiAvatarSessionId)
  const baseURL = config.API_URL;
  const path = "/avatar/response";
  const body = {
    transcript: transcript,
    ai_avatar_session_id: aiAvatarSessionId,
  };

  // To enable calling the API even when logged out
  // const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.post(`${baseURL + path}`, body
    // ,
    // {
    //   headers: {
    //     Authorization: idToken,
    //   },
    // }
  );
};

export const createAvatarConversationSession = async (patientId, aiAvatarSessionId, conversationId) => {
  const baseURL = config.API_URL;
  const path = "/avatar/create_session_and_conversation";
  const body = {
    patient_id: patientId,
    ai_avatar_session_id: aiAvatarSessionId,
    conversation_id: conversationId,
  };

  // To enable calling the API even when logged out
  // const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.post(`${baseURL + path}`, body
    // , {
    //   headers: {
    //     Authorization: idToken,
    //   },
    // }
  );
};

export const getPresignedUrlForAvatarSessionVideo = async (
  patientId,
  fileKey,
  operationType
) => {
  if (
    operationType.toLowerCase() !== "upload" &&
    operationType.toLowerCase() !== "download"
  )
    throw new Error("operationType can either be download or upload");
  const baseURL = config.API_URL;
  const path = `/avatar/get_presigned_url?patient_id=${patientId}&key=${fileKey}&operation_type=${operationType}`;

  // To enable calling the API even when logged out
  // const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.get(`${baseURL + path}`
    // , {
    //   headers: {
    //     Authorization: idToken,
    //   },
    // }
  );
};

export const saveAvatarSession = async (
  aiAvatarSessionId,
  conversationId,
  sessionStartTime,
  sessionEndTime,
  therapyId,
  patientId,
  fileKey,
  chatHistory,
  assessmentId
) => {
  const baseURL = config.API_URL;
  const path = "/avatar/save_session";
  const body = {
    ai_avatar_session_id: aiAvatarSessionId,
    conversation_id: conversationId,
    session_start_time: sessionStartTime,
    session_end_time: sessionEndTime,
    therapy_id: therapyId,
    patient_id: patientId,
    file_key: fileKey,
    chat_history: chatHistory,
    assessment_id: assessmentId,
  };

  // To enable calling the API even when logged out
  // const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.post(`${baseURL + path}`, body
    // , {
    //   headers: {
    //     Authorization: idToken,
    //   },
    // }
  );
};

export const listAllConversations = async (patientId) => {
  const baseURL = config.API_URL;
  const path = `/avatar/list_conversations?patient_id=${patientId}`;
  
  // To enable calling the API even when logged out
  // const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.get(`${baseURL + path}`
    // , {
    //   headers: {
    //     Authorization: idToken,
    //   },
    // }
  );
};

export const getInitiateConversation = async (therapy_id, language) => {
  const baseURL = config.API_URL;
  const path = `/avatar/initiate_conversation?therapy_id=${therapy_id}&language=${language}`;
  // const path = `/avatar/initiate_conversation?therapy_id=${therapy_id}`;
  
  // To enable calling the API even when logged out
  // const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.get(`${baseURL + path}`
    // , {
    //   headers: {
    //     Authorization: idToken,
    //   },
    // }
  );
};

export const getWellBeingScore = async (aiAvatarSessionId) => {
  const baseURL = config.API_URL;
  const path = `/avatar/overall_wellbeing_score?ai_avatar_session_id=${aiAvatarSessionId}`;

  // To enable calling the API even when logged out
  // const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return await axios.get(`${baseURL + path}`
    // , {
    //   headers: {
    //     Authorization: idToken,
    //   },
    // }
  );
};
