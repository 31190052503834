import "./DashboardHeader.css";

function DashboardHeader({ client }) {
  return (
    <div>
      <div className="heading">Dashboard</div>
      <div className="info-text dashboard-info-text">
        You can check the your overview data here
      </div>
    </div>
  );
}

export default DashboardHeader;
