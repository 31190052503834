import React from "react";
import "./NotExist.css";

const NotExist = ({ logOut }) => {
  return (
    <div className="not-exist">
      <div className="not-exist-text">
        It seems no therapy associated with this email address. Contact your
        therapists to create one therapy associated with this email or login
        with another email which have therapy associated with it.
      </div>
      <div onClick={logOut} className="not-exist-button">
        Logout
      </div>
    </div>
  );
};

export default NotExist;
