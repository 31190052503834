import { useEffect, useState } from "react";
import ASSETS from "../../assets/Assets";

import "./StarRating.css";
import { updateTherapistRatingByPatientAPI } from "../../api/patient";
import { useDispatch, useSelector } from "react-redux";
import { updateRating } from "../../store/client/clientSlice";
import { toast } from "react-hot-toast";

function StarRating({ rating }) {
  const [localRating, setLocalRating] = useState(rating);
  const therapyId = useSelector((store) => store.client.therapyId);
  const patientEmail = useSelector((store) => store.patient.email);
  const dispatch = useDispatch();

  useEffect(() => {
    setLocalRating(rating);
  }, [rating]);

  const handleRating = async (e) => {
    dispatch(updateRating(localRating));

    try {
      const response = await updateTherapistRatingByPatientAPI(
        therapyId,
        localRating
      );

      if (response.status === 200) {
        toast.success("Saved successfully.");
      }
    } catch (e) {
      toast.error("Save failed");
    }
  };

  return (
    <div
      className="star-rating-container"
      onMouseLeave={(e) => setLocalRating(rating)}
    >
      {Array.from({ length: localRating }, (value, idx) => (
        <img
          src={ASSETS.reputationIcon}
          alt="golden star"
          key={idx}
          onMouseEnter={(e) => setLocalRating(idx + 1)}
          onClick={handleRating}
        />
      ))}

      {Array.from({ length: 5 - localRating }, (value, idx) => (
        <img
          src={ASSETS.reputationIcon}
          className="inactive"
          alt="golden star"
          key={idx}
          onMouseEnter={(e) => setLocalRating(localRating + idx + 1)}
        />
      ))}
    </div>
  );
}

export default StarRating;
