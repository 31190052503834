import React from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import ClientDashboard from "../screens/dashboard/client/Dashboard";
import Sidebar from "../components/sidebar/Sidebar";
import ReportPage from "../screens/report/Report";
import Overview from "../screens/report/components/overview/Overview";
import Tasks from "../screens/report/components/tasks/Tasks";
import Files from "../screens/report/components/files/Files";
import Session from "../screens/report/components/session/Session";
import AllSessions from "../screens/report/components/allSessions/AllSessions";
import Settings from "../screens/settings/Settings";
import PatientAuthApp from "../components/auth/patient/PatientAuthApp";
import TherapistList from "../screens/therapistList/TherapistList";

import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";

import { patients_aws } from "../utils/aws-exports";
import { Provider } from "react-redux";
import store from "../store/rootStore";
import { Toaster } from "react-hot-toast";
import NotExist from "../components/notExist/NotExist";
import Invited from "../components/invited/Invited";
import { useEffect } from "react";
import AssessmentQuestions from "../components/assessmentQuestions/AssessmentQuestions";
import "./MindlyPatientApp.css";
import Assessments from "../screens/report/components/assessments/Assessments";
import AvatarPage from "../components/aiAvatar/main/pages/AvatarPage/AvatarPage.js";
import differenceInCalendarWeeksWithOptions from "date-fns/esm/fp/differenceInCalendarWeeksWithOptions/index.js";


Amplify.configure(patients_aws);

const theme = createTheme({
  palette: {
    primary: {
      main: "#5F6CE1",
    },
  },
});

const PatientApp = ({ logOut }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const therapyId = useSelector((store) => store.client.therapyId);
  const therapyStatus = useSelector((store) => store.client.therapyStatus);

  React.useEffect(() => {
    if (
      therapyId === "" &&
      (location.pathname.startsWith("/dashboard") ||
        location.pathname.startsWith("/report"))
    ) {
      navigate("/therapy_sessions");
    }
  }, []);

  if (therapyId === "DOES_NOT_EXIST") {
    return <NotExist logOut={logOut} />;
  }

  if (therapyStatus === "INVITED") {
    return <Invited />;
  }

  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Sidebar type="patient" />
          <div className="main-content-wrapper">
            <Routes>
              <Route path="/therapy_sessions" element={<TherapistList />} />
              <Route path="/dashboard" element={<ClientDashboard />} />
              <Route path="/report" element={<ReportPage />}>
                <Route path="" element={<Overview />} />
                {/* <Route path="tasks" element={<Tasks />} /> */}
                <Route path="assessments" element={<Assessments />} />
                <Route path="files" element={<Files />} />
                <Route path="ai-analysis" element={<Outlet />}>
                  <Route path="session" element={<Session />} />
                  <Route path="" element={<AllSessions />} />
                </Route>
              </Route>
              <Route path="/settings" element={<Settings logOut={logOut} />} />
            </Routes>
          </div>
          <Toaster position="bottom-right" reverseOrder={false} />
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
};

const MindlyPatientApp = () => {
  const location = useLocation();
  const [params, setParams] = useSearchParams();

  if (location.pathname.startsWith("/unauth/assessments")) {
    const decodedAssessmentId = (encodedAssessmentId) => {
      //Decode the base64 string assessmentId
      let decodedAssessmentId = null;
      if (encodedAssessmentId) {
        decodedAssessmentId = atob(encodedAssessmentId);
      }

      return decodedAssessmentId;
    };

    const assessment_id = decodedAssessmentId(params.get("assessment_id"));
    const assessment_type = params.get("assessment_type");

    return (
      <>
        {assessment_type && assessment_type === "ai_check_in" ? (
          <div className="avatar-parent-container">
            <Provider store={store}>
              <AvatarPage assessmentId={assessment_id} />
            </Provider>
          </div>

        ) : (
          <div className="assessment-questions-parent-container">
            <AssessmentQuestions assessmentId={assessment_id} />
          </div>
        )
        }
      </>
    );
  }

  return (
    <Authenticator.Provider>
      <Provider store={store}>
        <PatientAuthApp App={PatientApp} />
      </Provider>
    </Authenticator.Provider>
  );
};

export default MindlyPatientApp;
