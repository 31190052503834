import "./Tasks.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import {
  deleteTaskById,
  getAllTherapyTasksByTherapyId,
  updateTaskStatusById,
} from "../../../../api/patient";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import ASSETS from "../../../../assets/Assets";
import {
  setTasks,
  updateCheckTask,
} from "../../../../store/report/reportSlice";
import { format, parseISO } from "date-fns";
import { mysqlDatetimeToJavascriptDatetime } from "../../../../utils/helpers";
import AvatarPage from "../../../../components/aiAvatar/main/pages/AvatarPage/AvatarPage";
import { setRecordVideoButton } from "../../../../store/avatar/avatarSlice";
import {
  setIsSpeech,
  setGreetingsIsComplete,
} from "../../../../store/avatar/avatarSlice";

import { listAllConversations } from "../../../../api/avatar";

const containerStyleBasic = {
  borderRadius: "24px",
  border: "1px solid rgba(40, 40, 96, 0.15)",
  background: "#fff",
  boxShadow: "0px 8px 16px 0px rgba(41, 40, 45, 0.02)",
  height: "100%",
};

const textStyle = {
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "28px",
  letterSpacing: "0em",
  textAlign: "left",
  color: "#282860",
};

export function Task(props) {
  let defaultChecked = !!props.task.status;
  console.log(defaultChecked);
  return (
    <div
      className="report-task row"
      onClick={() => {
        props.onCheckboxTick(props.task.task_id, props.idx);
      }}
    >
      <div className="report-task-text-group row justify-flex-start">
        <input type="checkbox" checked={defaultChecked} />
        <div className="report-task-text">{props.task.task}</div>
      </div>
      <div className="report-task-creation-date">
        {format(
          parseISO(mysqlDatetimeToJavascriptDatetime(props.task.created_at)),
          "EEE do MMM y"
        )}
      </div>
    </div>
  );
}

function Tasks() {
  const therapy_id = useSelector((store) => store.client.therapyId);
  const patient_id = useSelector((store) => store.patient.patient_id);

  const userTranscript = useSelector((store) => store.avatar.userTranscript);
  const llmTranscript = useSelector((store) => store.avatar.llmTranscript);
  const recordVideoButton = useSelector(
    (store) => store.avatar.recordVideoButton
  );

  const tasks = useSelector((store) => store.report.tasks);
  const dispatch = useDispatch();

  const [avatarPanel, setAvatarPanel] = useState(false);
  const [allAvatarConversations, setAllAvatarConversations] = useState([]);

  useEffect(() => {
    listAllConversations(patient_id)
      .then((res) => {
        console.log("This is the avatar response: ", res.data);
        setAllAvatarConversations(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getAllTherapyTasksByTherapyId(therapy_id).then((data) => {
      let all_tasks = data.all_therapy_tasks.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      dispatch(setTasks(all_tasks));
    });
  }, []);

  function onCheckboxTick(task_id, idx) {
    const status = tasks[idx].status ? 0 : 1;
    updateTaskStatusById(task_id, status).then((res) => {
      dispatch(
        updateCheckTask({
          idx,
          status,
        })
      );
    });
  }

  const totalTasks = tasks.length;
  const completedTasks = tasks.filter((element) => element.status).length;
  const remainingTasks = totalTasks - completedTasks;

  const AiCheckIn = () => {
    return (
      <div
        style={{
          ...containerStyleBasic,
          width: "320px",
          minHeight: "200px",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            padding: "10px 20px",
          }}
        >
          <div
            style={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "46px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#282860",
              opacity: 0.8,
            }}
          >
            Ai Check-in Status
          </div>
          <div
            style={{
              ...textStyle,
              opacity: 0.5,
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <img src={ASSETS.stopWatch} alt="monthly" />
            <span>Monthly</span>
          </div>
          {/* <div>
            <span
              style={{
                ...textStyle,
                opacity: 0.5,
                fontSize: "14px",
              }}
            >
              Newly Assigned on:
            </span>
            <span style={{ ...textStyle, opacity: 0.8, fontSize: "14px" }}>
              {" "}
              14 May 2024
            </span>
          </div> */}
          {/* <div style={{ ...textStyle, opacity: 0.5, fontWeight: 700 }}>
            12 days remaining to be assigned again
          </div> */}
          <div style={{ ...textStyle, marginBottom: "10px" }}>
            <span style={{ opacity: 0.5, fontWeight: 500 }}>Last Taken:</span>
            <span style={{ opacity: 0.8, fontWeight: 600 }}>
              {" "}
              {allAvatarConversations.length !== 0
                ? new Date(
                    allAvatarConversations[0]?.created_at
                  ).toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })
                : "No sessions recorded"}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Poppins",
              fontSize: "12px",
              fontStyle: "italic",
              fontWeight: 400,
              lineHeight: "18px",
              letterSpacing: "0em",
              flexDirection: "column",
              gap: "5px",
              opacity: 0.8,
            }}
          >
            <img src={ASSETS.robotSmile} alt="check in status" />
            <div>(Ai Avatar Conversation)</div>
          </div>
        </div>
        <div
          style={{
            borderTop: "1px solid rgba(40, 40, 96, 0.15)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "21px",
            letterSpacing: "0em",
            textAlign: "center",
            color: "#5F6CE1",
            padding: "10px",
            cursor: "pointer",
          }}
          onClick={() => setAvatarPanel(true)}
        >
          Take Now
        </div>
      </div>
    );
  };

  const AiAvatarPanel = () => {
    return (
      <>
        <div
          style={{
            ...containerStyleBasic,
            height: "100%",
            width: "100%",
            padding: "20px 30px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                cursor: "pointer",
                borderRadius: "50%",
                border: "1px solid #ABB2F2",
                background: "#C9CEFA",
                height: "35px",
                width: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className={`${recordVideoButton ? "disabled" : ""}`}
              onClick={() => {
                setAvatarPanel(false);
                dispatch(setIsSpeech(false));
                dispatch(setRecordVideoButton(false));
                dispatch(setGreetingsIsComplete(false));
                console.log("clicked this button");
              }}
            >
              <img src={ASSETS.backArrow} alt="back" />
            </div>
            <div>Ai Check-in Status</div>
          </div>
          <div
            style={{
              display: "flex",
              height: "calc(100% - 50px)",
              width: "100%",
              gap: "20px",
              flexDirection: "column",
            }}
          >
            <AvatarPage />
          </div>
          {/* <div
            style={{
              width: "100%",
              height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!startConversation && <div
              style={{
                borderRadius: "8px",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "21px",
                letterSpacing: "0em",
                textAlign: "center",
                padding: "10px 20px",
                background: "#5F6CE1",
                color: "#FFFFFF",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(setRecordVideoButton(true));
                setStartConversation(true);
              }}
            >
              Start the Assessment
            </div>}
          </div> */}
        </div>
      </>
    );
  };

  return (
    <>
      {avatarPanel ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <AiAvatarPanel /> */}
          {AiAvatarPanel()}
        </div>
      ) : (
        <div className="report-tasks-container">
          {/* <div className="report-task-and-advise-summary">
            <div className="report-task-and-advise-summary-subheading row">
              <div className="report-task-and-advise-summary-pasteboard">
                <img src={ASSETS.tasksAndAdvice} alt="tasks and advice" />
              </div>
              <div className="report-task-and-advise-summary-subheading-text">
                Tasks & Advise Given Summary
              </div>
            </div>
            <div className="report-tasks-metrics">
              <div className="report-task-metric">
                <div className="report-task-metric-value">{totalTasks}</div>
                <div className="report-task-metric-text">Total Tasks Given</div>
              </div>
              <div className="report-task-metric">
                <div className="report-task-metric-value">{completedTasks}</div>
                <div className="report-task-metric-text">Tasks Completed</div>
              </div>
              <div className="report-task-metric">
                <div className="report-task-metric-value">{remainingTasks}</div>
                <div className="report-task-metric-text">Tasks Incomplete</div>
              </div>
            </div>
          </div>
          <div className="report-tasks">
            {tasks.map((task, idx) => {
              return (
                <Task idx={idx} task={task} onCheckboxTick={onCheckboxTick} />
              );
            })}
          </div> */}
          <AiCheckIn />
        </div>
      )}
    </>
  );
}

export default Tasks;
