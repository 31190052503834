import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./Assessments.css";
import { toast } from "react-hot-toast";
import { getAllAssessmentAPI } from "../../../../api/assessments";
import AssessmentList from "./components/AssessmentList/AssessmentList";
import AvatarPanel from "./components/AvatarPanel/AvatarPanel";
import AssessmentQuestions from "../../../../components/assessmentQuestions/AssessmentQuestions";

const processData = (data) => {
  // filter completed assessment
  const assessment = data
    .filter((item) => {
      return item.score === -1;
    })
    // sort the assessment
    .sort(function (a, b) {
      return new Date(a.due_date) - new Date(b.due_date);
    })
    .filter((item) => {
      if (new Date() < new Date(item.due_date)) {
        item["status"] = "upcoming";
      } else {
        item["status"] = "missed";
      }
      return item;
    });

  return assessment;
};

const AssessmentListPanel = ({ assessmentData, setPanel, setAssessmentId }) => {
  return (
    <div>
      <div className="assessment-container-heading">Assessment List</div>
      <div className="assessment-list">
        {assessmentData.length !== 0 ? (
          <AssessmentList
            assessments={assessmentData}
            setPanel={setPanel}
            setAssessmentId={setAssessmentId}
          />
        ) : (
          <div>No assessments assigned</div>
        )}
      </div>
    </div>
  );
};

const Assessments = () => {
  const [assessmentData, setAssessmentData] = useState([]);
  const therapyId = useSelector((store) => store.client.therapyId);
  const [panel, setPanel] = useState(0);
  const [assessmentId, setAssessmentId] = useState("");

  useEffect(() => {
    if (panel === 0) {
      getAllAssessmentAPI(therapyId)
        .then((res) => {
          //toast.success("Data Fetched");
          setAssessmentData(processData(res.data));
        })
        .catch((err) => {
          toast.error("Error in fetching Assessments");
          console.log(err);
        });
    }
  }, [panel]);

  //   let assessments = [
  //     {
  //       assessment_id: "3110a9b1-62be-4760-855a-deb5ef36e06e",
  //       assessment_type: "PHQ9",
  //       completed_at: null,
  //       created_at: "2024-03-01 05:53:53",
  //       due_date: "2024-03-08 05:53:53",
  //       maximum_score: 27,
  //       score: -1,
  //       therapy_id: "5a563b80-4d70-47b5-abd0-1e0e5866706f",
  //     },
  //     {
  //       assessment_id: "3110a9b1-62be-4760-855a-deb5ef36e06e",
  //       assessment_type: "GAD7",
  //       completed_at: null,
  //       created_at: "2024-01-02 05:53:53",
  //       due_date: "2024-01-09 05:53:53",
  //       maximum_score: 21,
  //       score: -1,
  //       therapy_id: "5a563b80-4d70-47b5-abd0-1e0e5866706f",
  //     },
  //     {
  //       assessment_id: "3110a9b1-62be-4760-855a-deb5ef36e06e",
  //       assessment_type: "AI  CHECK IN",
  //       completed_at: null,
  //       created_at: "2024-02-01 05:53:53",
  //       due_date: "2024-02-08 05:53:53",
  //       maximum_score: 1,
  //       score: -1,
  //       therapy_id: "5a563b80-4d70-47b5-abd0-1e0e5866706f",
  //     },
  //     {
  //       assessment_id: "3110a9b1-62be-4760-855a-deb5ef36e06e",
  //       assessment_type: "PHQ9",
  //       completed_at: null,
  //       created_at: "2024-03-10 05:53:53",
  //       due_date: "2024-03-17 05:53:53",
  //       maximum_score: 27,
  //       score: -1,
  //       therapy_id: "5a563b80-4d70-47b5-abd0-1e0e5866706f",
  //     },
  //     {
  //       assessment_id: "3110a9b1-62be-4760-855a-deb5ef36e06e",
  //       assessment_type: "GAD7",
  //       completed_at: null,
  //       created_at: "2024-03-11 05:53:53",
  //       due_date: "2024-03-18 05:53:53",
  //       maximum_score: 21,
  //       score: -1,
  //       therapy_id: "5a563b80-4d70-47b5-abd0-1e0e5866706f",
  //     },
  //     {
  //       assessment_id: "3110a9b1-62be-4760-855a-deb5ef36e06e",
  //       assessment_type: "AI CHECK IN",
  //       completed_at: null,
  //       created_at: "2024-03-17 05:53:53",
  //       due_date: "2024-03-24 05:53:53",
  //       maximum_score: 1,
  //       score: -1,
  //       therapy_id: "5a563b80-4d70-47b5-abd0-1e0e5866706f",
  //     },
  //     {
  //       assessment_id: "3110a9b1-62be-4760-855a-deb5ef36e06e",
  //       assessment_type: "PHQ9",
  //       completed_at: "2024-03-05 05:53:53",
  //       created_at: "2024-03-01 05:53:53",
  //       due_date: "2024-03-08 05:53:53",
  //       maximum_score: 27,
  //       score: 15,
  //       therapy_id: "5a563b80-4d70-47b5-abd0-1e0e5866706f",
  //     },
  //     {
  //       assessment_id: "3110a9b1-62be-4760-855a-deb5ef36e06e",
  //       assessment_type: "GAD7",
  //       completed_at: "2024-03-05 05:53:53",
  //       created_at: "2024-03-01 05:53:53",
  //       due_date: "2024-03-08 05:53:53",
  //       maximum_score: 21,
  //       score: 18,
  //       therapy_id: "5a563b80-4d70-47b5-abd0-1e0e5866706f",
  //     },
  //     {
  //       assessment_id: "3110a9b1-62be-4760-855a-deb5ef36e06e",
  //       assessment_type: "AI CHECK IN",
  //       completed_at: "2024-03-05 05:53:53",
  //       created_at: "2024-03-01 05:53:53",
  //       due_date: "2024-03-08 05:53:53",
  //       maximum_score: 1,
  //       score: 1,
  //       therapy_id: "5a563b80-4d70-47b5-abd0-1e0e5866706f",
  //     },
  //   ];

  //   let assessmentData = processData(assessments);

  return (
    <div className="assessment-container">
      {panel === 0 ? (
        <AssessmentListPanel
          assessmentData={assessmentData}
          setPanel={setPanel}
          setAssessmentId={setAssessmentId}
        />
      ) : panel === 1 ? (
        <AssessmentQuestions
          setPanel={setPanel}
          assessmentId={assessmentId}
          showBackButton={true}
        />
      ) : panel === 2 ? (
        <AvatarPanel setPanel={setPanel} assessmentId={assessmentId} />
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Assessments;
