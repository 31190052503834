import "./Session.css";

import { useEffect, useState, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";

import ASSETS from "../../../../assets/Assets";
import TriggerMap from "../triggerMap/TriggerMap";
import EditSessionSummaryModal from "../editSessionSummaryModal/EditSessionSummaryModal";
import {
  fetchMeetingPatientEmotionsAPI,
  fetchMeetingSummaryAPI,
} from "../../../../api/meetingAIAnalysis";

const formatNextSessionPlanning = (sessionPlanning) => {
  let formattedSessionPlanning = sessionPlanning.slice(22).split("\\n");

  return formattedSessionPlanning;
};

function Session({ currentSession }) {
  const [showEditSessionSummaryModal, setShowEditSessionSummaryModal] =
    useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [videoDuration, setVideoDuration] = useState(0);
  const videoPlayerRef = useRef();

  const [searchParams] = useSearchParams();
  const video_id = searchParams.get("video_id");

  const [videoURL, setVideoURL] = useState("");
  const [baseVideoURL, setBaseVideoURL] = useState("");
  const [meetingSummary, setMeetingSummary] = useState("");
  const [nextMeetingPlanning, setNextMeetingPlanning] = useState([""]);
  const [graphData, setGraphData] = useState([]);
  const [timestamp, setTimestamp] = useState(0);

  const updateVideoURL = (timestamp) => {
    setTimestamp(timestamp);
  };

  useEffect(() => {
    setVideoURL(baseVideoURL + "#t=" + timestamp);
  }, [timestamp]);

  useEffect(() => {
    if (video_id) {
      fetchMeetingSummaryAPI(video_id).then((res) => {
        setVideoURL(res["video_url"]);
        setBaseVideoURL(res["video_url"]);
        setMeetingSummary(res["summary"]);

        const nextSessionPlanning = formatNextSessionPlanning(
          res["next_session_planning"]
        );

        setNextMeetingPlanning(nextSessionPlanning);
      });
      fetchMeetingPatientEmotionsAPI(video_id).then((res) => {
        console.log(res);
        setGraphData(res);
      });
    }
  }, [video_id]);

  const handleLoadedMetadata = () => {
    setVideoDuration(parseInt(videoPlayerRef.current.duration));
  };

  return (
    <>
      <EditSessionSummaryModal
        show={showEditSessionSummaryModal}
        onClose={(e) => setShowEditSessionSummaryModal(false)}
      />
      <div className="report-session-container">
        <div className="report-session-header row justify-flex-start">
          <Link to="/report/ai-analysis" className="report-session-back-btn">
            <img src={ASSETS.circularBackBtn} alt="circular back btn" />
          </Link>
          <div className="report-session-heading">All Sessions</div>
        </div>
        <div className="report-session-video-and-summary">
          <div className="session-video">
            <video
              ref={videoPlayerRef}
              src={videoURL}
              alt="session"
              controls
              onLoadedMetadata={handleLoadedMetadata}
            />
          </div>

          <div className="session-summary">
            <div className="session-summary-header row justify-flex-start">
              <div className="session-summary-text">Session Summary</div>
            </div>

            <div className="session-summary-container">{meetingSummary}</div>
          </div>
        </div>
        <div style={{ width: "100%", display: "flex", gap: "2rem" }}>
          {/* <div className="session-trigger-map">
            <div className="session-trigger-map-header row justify-flex-start">
              <div className="session-trigger-map-heading">Trigger Map</div>
              <img
                src={ASSETS.infoOutline}
                alt="info outline"
                className="cursor-pointer"
                onMouseOver={(e) => setShowInfoPopup(true)}
                onMouseLeave={(e) => setShowInfoPopup(false)}
              />

              {showInfoPopup && (
                <div className="session-trigger-map-info-popup">
                  This is a visual representation of the emotion patterns of the
                  client during a session. This map helps point out any sudden
                  spikes in the emotion to compare with positive and negative
                  transcript and understand the clients behavioural pattern.
                </div>
              )}
            </div>

            <TriggerMap
              data={graphData}
              updateVideoURL={updateVideoURL}
              duration={graphData[0]?.duration}
            />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Session;
