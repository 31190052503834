import { useState, useEffect, useRef } from "react";

import {
  getWellBeingScore,
} from "../../../../../api/avatar";

import "./RealtimeAiAssesement.css";
import { Doughnut } from "react-chartjs-2";

const getDonutChartData = (value) => {
  let data = {
    // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    labels: false,
    datasets: [
      {
        // label: '# of Votes',
        data: [value, 100 - value],
        backgroundColor: ["rgba(236, 116, 64, 1)", "rgba(216, 216, 216, 1)"],
        borderColor: ["rgba(236, 116, 64, 1)", "rgba(216, 216, 216, 0)"],
        borderWidth: 0,
      },
    ],
  };

  return data;
};

const getDonutChartOptions = (value) => {
  let res = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      annotation: {
        annotations: {
          label1: {
            type: "label",
            xValue: 0,
            yValue: 0,
            // backgroundColor: "rgba(245,245,245)",
            content: value + "%",
            // [data?.datasets[0]?.data[0] + "%"]
            font: [
              {
                size: "40px",
                weight: 400,
              },
            ],
            // color: "rgba(73, 72, 79, 1)",
            // yAdjust: 30,
            // position: "bottom",
          },
        },
      },
    },
    rotation: -90,
    circumference: 180,
    responsive: true,
    maintainAspectRatio: false,
    cutout: "88%",
    // borderJoinStyle: "round",
    borderRadius: 50,
  };

  return res;
};

const RealtimeAiAssesement = ({
  aiAvatarSessionId,
  resetUploadProgress
}) => {

  const [wellBeingScoreFromTranscripts, setWellBeingScoreFromTranscripts] = useState(0);

  useEffect(() => {
    const fetchtWellBeingScor = async () => {
      const wellBeingScore = await getWellBeingScore(
        aiAvatarSessionId
      );

      if (wellBeingScore && wellBeingScore.data && wellBeingScore.data[0].well_being_score_from_transcripts) {
        setWellBeingScoreFromTranscripts(Math.round(wellBeingScore.data[0].well_being_score_from_transcripts));

      }

    };

    if (aiAvatarSessionId) fetchtWellBeingScor();
  }, []);

  return (
    <div className="realtime-ai-assesement-container">
      <div className="realtime-ai-assesement-title">Do you need to see a Therapist?</div>
      <div className="realtime-ai-assesement-description">Based on your conversation with our AI, we were able to evaluate wether it would be good for your well-being to seek therapy.</div>
      <div className="realtime-ai-assesement-result-container">
        <div className="realtime-ai-assesement-result">
          <div className="realtime-ai-assesement-result-chart">
            <Doughnut
              data={getDonutChartData(parseInt(wellBeingScoreFromTranscripts))}
              options={getDonutChartOptions(wellBeingScoreFromTranscripts)}
            >
            </Doughnut>
            <div className="realtime-ai-assesement-result-score-container">
              <span className="realtime-ai-assesement-result-score">{wellBeingScoreFromTranscripts}</span>/100
            </div>
          </div>
        </div>
      </div>
      <div className="realtime-ai-assesement-explanation">
        {
          wellBeingScoreFromTranscripts >= 25 && wellBeingScoreFromTranscripts <= 49 ? (
            <div>
              A score between 25 and 49 indicates that it might be beneficial to seek professional help. Sometimes, even when we believe we're handling our challenges, an external perspective can provide valuable insights and coping strategies. There's strength in seeking support, and taking this step is a positive move towards taking care of your mental health.
            </div>
          ) : wellBeingScoreFromTranscripts >= 50 && wellBeingScoreFromTranscripts <= 74 ? (
            <div >
              If your score falls between 50 and 74, it suggests that while you're managing reasonably well, there may be some areas of concern worth monitoring. It's essential to pay attention to your mental and emotional health and to seek support or resources as needed. Engaging in self-reflection, journaling, or talking to trusted friends or family members can be helpful in maintaining your well-being.
            </div>
          ) : wellBeingScoreFromTranscripts >= 75 ? (
            <div >
              A score of 75 to 100 suggests it's time to focus on self-care. Remember, taking care of yourself isn't a luxury; it's a necessity. Simple activities like taking a walk, enjoying your favorite music, or reaching out to a friend can make a significant difference. Your well-being matters, and it's okay to put your needs first.
            </div>
          ) : (
            <div >
              With a score of 0 to 24, we strongly recommend reaching out for professional assistance urgently. Remember, asking for help is a sign of strength, not weakness. Professional support can provide the necessary guidance and help you navigate through tough times with resilience and hope. You're not alone, and help is available.
            </div>
          )
        }
      </div>
      <div className="realtime-ai-assesement-cta-container">
        <div
          className="realtime-ai-assesement-cta-find-therapist"
          onClick={() => window.location.href = 'https://www.imbesideyou.com/mental-health'}
        >Find a Therapist</div>
        <div className="realtime-ai-assesement-cta-another-conversation-container">
          <div
            className="realtime-ai-assesement-cta-another-conversation-main"
            onClick={resetUploadProgress}
          >Start Another Conversation</div>
          <div
            className="realtime-ai-assesement-cta-another-conversation-descriptoin"
          >You have 2 Conversation Token to try out our Ai
          </div>
        </div>
      </div>
    </div>
  )
}

export default RealtimeAiAssesement;
