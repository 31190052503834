import { createSlice } from "@reduxjs/toolkit";

const reportSlice = createSlice({
  name: "report",
  initialState: {
    activity: [
      {
        author: "Maya",
        action: "add new file",
        actionItem: "Insurance Certificate",
        timestamp: new Date().toISOString(),
      },
      {
        author: "Maya",
        action: "add new file",
        actionItem: "Perscriptions",
        timestamp: new Date().toISOString(),
      },
      {
        author: "Maya",
        action: "add new file",
        actionItem: "Client consent form",
        timestamp: new Date().toISOString(),
      },
      {
        author: "Maya",
        action: "add the summary",
        actionItem: "Session",
        timestamp: new Date().toISOString(),
      },
    ],

    files: [],

    sessions: [
      {
        well_being_status: "positive", // positive, mixed and distressed.
        session_number: 1,
        timestamp: new Date().toISOString(),
        pinned: true,
      },
      {
        well_being_status: "mixed", // positive, mixed and distressed.
        session_number: 2,
        timestamp: new Date().toISOString(),
        pinned: false,
      },
      {
        well_being_status: "distressed", // positive, mixed and distressed.
        session_number: 3,
        timestamp: new Date().toISOString(),
        pinned: false,
      },
    ],

    tasks: [],
  },
  reducers: {
    pinFile(state, action) {
      state.files[action.payload.index].pinned = action.payload.value;
    },
    removeFile(state, action) {
      state.files = state.files.filter(
        (value, index) => index !== action.payload
      );
    },
    setFiles(state, action) {
      state.files = action.payload;
      return state;
    },
    setTasks(state, action) {
      state.tasks = action.payload;
      return state;
    },
    removeTaskByIdx(state, action) {
      const index = action.payload.idx;
      return {
        ...state,
        tasks: [
          ...state.tasks.slice(0, index),
          ...state.tasks.slice(index + 1),
        ],
      };
    },
    updateCheckTask(state, action) {
      const idx = action.payload.idx;
      const status = action.payload.status;
      const newTasks = [...state.tasks];
      newTasks[idx] = {
        ...state.tasks[idx],
        status: status,
      };
      return {
        ...state,
        tasks: newTasks,
      };
    },
  },
});

export const {
  pinFile,
  removeFile,
  setFiles,
  setTasks,
  updateCheckTask,
  removeTaskByIdx,
} = reportSlice.actions;

export default reportSlice.reducer;
