import React from "react";
import Loader from "../loader/Loader";
import { useState } from "react";
import "./AssessmentQuestions.css";
import { useEffect } from "react";
import { Modal } from "antd";
import {
  getSingleAssessmentAPI,
  createAssessmentAPI,
} from "../../api/assessments";
import ASSETS from "../../assets/Assets";

const processQuestions = (data) => {
  let processedQuestions = [];
  processedQuestions.push({
    assessment_question_order: data[0]["assessment_question_order"],
    assessment_question_id: data[0]["assessment_question_id"],
    assessment_question: data[0]["assessment_question"],
    options: [
      {
        assessment_option_id: data[0]["assessment_option_id"],
        assessment_option: data[0]["assessment_option"],
        assessment_option_score: data[0]["assessment_option_score"],
      },
    ],
    selected_option_id: "",
    selected_option_score: -1,
  });

  //Add
  let j = 0;
  for (let i = 1; i < data.length; i++) {
    if (
      data[i]["assessment_question_order"] ===
      processedQuestions[j]["assessment_question_order"]
    ) {
      processedQuestions[j]["options"].push({
        assessment_option_id: data[i]["assessment_option_id"],
        assessment_option: data[i]["assessment_option"],
        assessment_option_score: data[i]["assessment_option_score"],
      });
    } else {
      processedQuestions.push({
        assessment_question_order: data[i]["assessment_question_order"],
        assessment_question_id: data[i]["assessment_question_id"],
        assessment_question: data[i]["assessment_question"],
        options: [
          {
            assessment_option_id: data[i]["assessment_option_id"],
            assessment_option: data[i]["assessment_option"],
            assessment_option_score: data[i]["assessment_option_score"],
          },
        ],
        selected_option_id: "",
        selected_option_score: -1,
      });
      j++;
    }
  }

  //Sort the options
  for (let i in processedQuestions) {
    let options = processedQuestions[i]["options"];
    const sortedOptions = options.sort(function (a, b) {
      return a.assessment_option_score - b.assessment_option_score;
    });
    processedQuestions[i]["options"] = sortedOptions;
  }

  return processedQuestions;
};

const ResultModal = ({
  open,
  handleCancel,
  handleOk,
  onSubmit,
  totalScore,
  assessmentType,
}) => {
  const submitForm = async () => {
    // Show loading message
    setShowLoader(true);
    // On successfull message show thankyou
    let isSuccessful = await onSubmit();
    console.log("inside submit form", isSuccessful);

    if (isSuccessful) {
      setShowLoader(false);
      setShowThankyouMessage(true);
    } else {
      setShowLoader(false);
      alert("Failed to share data !");
    }
  };

  const [showLoader, setShowLoader] = useState(false);
  const [showThankyouMessage, setShowThankyouMessage] = useState(false);

  let maximumScore = assessmentType === "PHQ9" ? 27 : 21;

  return (
    <Modal
      title=""
      open={open}
      onCancel={handleCancel}
      footer={null}
      width={700}
    >
      {showLoader ? (
        <Loader />
      ) : (
        <div className="result-modal">
          {!showThankyouMessage ? (
            <div className="result-modal-container">
              <div className="result-modal-container-heading">Your Score</div>
              <div className="result-modal-container-score">
                <span>{totalScore}</span>/{maximumScore}
              </div>
              <div
                onClick={submitForm}
                className="result-modal-container-submit-button"
              >
                Share Your Score with Therapist
              </div>
            </div>
          ) : (
            <div>Data successfully shared with the therapist</div>
          )}
        </div>
      )}
    </Modal>
  );
};

const AssessmentQuestionList = ({
  assessmetType,
  setId,
  questionData,
  assessmentId,
  showBackButton,
  setPanel,
}) => {
  const [questions, setQuestions] = useState([]);
  const [questionAnsweredNunber, setQuestionAnsweredNumber] = useState(0);
  const [totalScore, setTotalScore] = useState(-1);

  const assessmentTypeLabel = {
    PHQ9: "PHQ 9",
    GAD7: "GAD 7",
  };

  const [open, setOpen] = useState(false);
  const [dataSharedSuccessfully, setDataSharedSuccessfully] = useState(false);

  const showModal = () => {
    let score = 0;
    for (let i in questions) {
      score += questions[i]["selected_option_score"];
    }
    setTotalScore(score);

    setOpen(true);
  };

  const handleOk = (e) => {
    setOpen(false);
  };

  const handleCancel = (e) => {
    setOpen(false);
    if (dataSharedSuccessfully) {
      setId(3);
    }
  };
  useEffect(() => {
    setQuestions(processQuestions(questionData));
  }, [assessmetType]);

  //Based on the question update the selected option id
  const handleUpdateSelectedOption = (question, option) => {
    if (
      questions[question["assessment_question_order"] - 1][
        "selected_option_score"
      ] === -1
    ) {
      setQuestionAnsweredNumber((prevState) => prevState + 1);
    }
    // Get the questions and create a deep copy
    let updatedQuestions = [...questions];
    updatedQuestions[question["assessment_question_order"] - 1][
      "selected_option_id"
    ] = option["assessment_option_id"];
    updatedQuestions[question["assessment_question_order"] - 1][
      "selected_option_score"
    ] = option["assessment_option_score"];

    setQuestions(updatedQuestions);
  };

  const handleSubmitAnswer = async () => {
    let completedAt = new Date().toISOString().slice(0, 19).replace("T", " ");
    let answers = questions;

    const isSuccessful = createAssessmentAPI(
      assessmentId,
      answers,
      totalScore,
      completedAt
    )
      .then(() => {
        // After submitting
        setDataSharedSuccessfully(true);
        return true;
      })
      .catch(() => {
        setDataSharedSuccessfully(false);
        return false;
      });

    return isSuccessful;
  };

  return (
    <div className="assessmenent-question-list-container">
      <div className="assessmenent-question-list-heading">
        {showBackButton ? (
          <img
            src={ASSETS.backArrow}
            alt="back"
            onClick={() => setPanel(0)}
            className="assessmenent-question-list-back-button"
          />
        ) : (
          <div></div>
        )}
        {assessmentTypeLabel[assessmetType]}
      </div>
      <div className="assessmenent-question-list-subheading">
        Over the past few days, how oftern you have been bothered by any of the
        following problems?
      </div>
      {questions.map((item) => {
        return (
          <div
            key={item["assessment_question_order"]}
            className="assessmenent-question-item"
          >
            <div className="assessmenent-question-item-question-order">
              <div>{item["assessment_question_order"]}</div>
            </div>
            <div className="assessmenent-question-item-question-and-option">
              <div className="assessmenent-question-item-question">
                {item["assessment_question"]}
              </div>
              <div className="assessmenent-question-item-option-container">
                {item["options"].map((option) => {
                  return (
                    <div
                      className="assessmenent-question-item-option"
                      key={option["assessment_option_score"]}
                      onClick={() => handleUpdateSelectedOption(item, option)}
                      style={
                        item["selected_option_id"] ===
                        option["assessment_option_id"]
                          ? {
                              border: "1px solid rgba(40, 40, 96, 0.5)",
                              backgroundColor: "rgba(171, 178, 242, 0.6)",
                            }
                          : {}
                      }
                    >
                      {option["assessment_option"]}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
      <div className="assessmenent-question-list-button-container">
        {questionAnsweredNunber === questions.length ? (
          <div
            className="assessmenent-question-list-button"
            onClick={showModal}
          >
            Get Result
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <ResultModal
        open={open}
        handleOk={handleOk}
        handleCancel={handleCancel}
        onSubmit={handleSubmitAnswer}
        totalScore={totalScore}
        assessmentType={assessmetType}
      />
    </div>
  );
};

const showAssessmentComponents = (
  id,
  setId,
  assessmentType,
  questionData,
  assessmentId,
  showBackButton,
  setPanel
) => {
  switch (id) {
    case 0:
      return <div>Invalid Assessment Id</div>;
    case 1:
      return <div>Assessment Already Completed</div>;
    case 2:
      return (
        <AssessmentQuestionList
          assessmetType={assessmentType}
          setId={setId}
          questionData={questionData}
          assessmentId={assessmentId}
          showBackButton={showBackButton}
          setPanel={setPanel}
        />
      );
    case 3:
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          Thankyou for completing the assessment.
          {showBackButton ? (
            <div onClick={() => setPanel(0)} style={{ cursor: "pointer" }}>
              Click to go Back
            </div>
          ) : (
            <div></div>
          )}
        </div>
      );
    default:
      return <div>Invalid Assessment Id</div>;
  }
};

const AssessmentQuestions = ({
  assessmentId,
  showBackButton = false,
  setPanel,
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  const [id, setId] = useState(-1);

  const [assessmentType, setAssessmentType] = useState("");

  useEffect(() => {
    // Call the api and get the assessmentDetail
    setShowLoader(true);
    getSingleAssessmentAPI(assessmentId)
      .then((res) => {
        if (res["data"]) {
          // If completed show already completed the assessment
          // Here we are sending assessment score in an array which is single data point
          if (res["data"].length === 1) {
            setId(1);
          } else {
            // If valid response and not completed
            let assessmentType = res["data"][0]["assessment_type"];
            setId(2);
            setAssessmentType(assessmentType);
            setQuestionData(res["data"]);
          }
          setShowLoader(false);
        } else {
          // Else show Invalid Assessment Id Component
          setId(0);
          setShowLoader(false);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        console.log(err);
      });
  }, [assessmentId]);

  return (
    <div className="assessment-questions-container">
      {assessmentId ? (
        showLoader ? (
          <Loader />
        ) : (
          showAssessmentComponents(
            id,
            setId,
            assessmentType,
            questionData,
            assessmentId,
            showBackButton,
            setPanel
          )
        )
      ) : (
        <div>Invalid Page</div>
      )}
    </div>
  );
};

export default AssessmentQuestions;
