/* eslint-disable react/prop-types */
const progressBar = {
  height: "10px",
  width: "80%",
  backgroundColor: "#2c3254",
  borderRadius: "1rem",
  border: "1.446px solid rgba(44, 50, 84, 0.15)",
  background: "#fff",

  display: "flex",
  alignItems: "center",
  marginBottom: "20px",
};

const progressStyle = {
  borderRadius: "1rem",
  border: "1.446px solid rgba(44, 50, 84, 0.15)",
  height: "110%",
};

const ProgressBar = ({ progress, barColor }) => {
  return (
    <div style={progressBar}>
      <div
        style={{
          width: progress + "%",
          display: progress === 0 ? "none" : "",
          background: barColor ? barColor : "#b899f6",
          ...progressStyle,
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
