import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

export const fetchAllActivitiesAPI = async (therapy_id) => {
  const baseURL = config.API_URL;
  const path = `/activities?therapy_id=${therapy_id}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const res = await axios.get(baseURL + path, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};
