import "./Report.css";
import { NavLink, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

function Report() {
  const recordVideoButton = useSelector(
    (store) => store.avatar.recordVideoButton
  );

  return (
    <div className="report-page-wrapper">
      <div className="report-heading">Report</div>

      <div className="report-content-container">
        <div
          className={`report-content-tab-selector-header ${
            recordVideoButton ? "disabled" : ""
          }`}
        >
          <NavLink
            to="/report"
            className={({ isActive }) =>
              isActive
                ? "report-content-tab-selector active"
                : "report-content-tab-selector"
            }
            end
          >
            Overview
          </NavLink>

          <NavLink
            to="/report/assessments"
            className={({ isActive }) =>
              isActive
                ? "report-content-tab-selector active"
                : "report-content-tab-selector"
            }
          >
            Assessments
          </NavLink>

          <NavLink
            to="/report/files"
            className={({ isActive }) =>
              isActive
                ? "report-content-tab-selector active"
                : "report-content-tab-selector"
            }
          >
            Files
          </NavLink>

          <NavLink
            to="/report/ai-analysis"
            className={({ isActive }) =>
              isActive
                ? "report-content-tab-selector active"
                : "report-content-tab-selector"
            }
          >
            AI Analysis
          </NavLink>
        </div>

        <div className="report-content-section scroll-thin-vertical">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Report;
