import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthenticator } from "@aws-amplify/ui-react";
import PatientAuthPage from "../../../screens/auth/patient/PatientAuthPage";
import {
  getPatientByEmailAPI,
  getPatientByEmailAPIv2,
} from "../../../api/patient";
import {
  logoutResetState,
  setPatient,
} from "../../../store/patient/patientSlice";
import {
  getPatientDashboardByEmailAPI,
  getPatientDashboardByTherapyIdAPI,
} from "../../../api/patient";
import { updatePatientDashboard } from "../../../store/client/clientSlice";
import { ThreeDots } from "react-loader-spinner";
import TherapistList from "../../../screens/therapistList/TherapistList";
import { useNavigate } from "react-router-dom";

function PatientAuthApp({ App }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, signOut, authStatus } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ]);

  const therapyId = useSelector((store) => store.client.therapyId);
  const patient_email = useSelector((store) => store.patient.email);

  useEffect(() => {
    console.log(user, authStatus);
    if (authStatus === "authenticated" && user.attributes) {
      console.log(user, authStatus);
      getPatientByEmailAPIv2(user.attributes.email).then((res) => {
        dispatch(setPatient(res.data));
        // Get Therapy
        // If no therapy return no therapy
        // else if therapy is invited load that button
        // else get the dashboard
        if (res.data.therapy_id === "DOES_NOT_EXIST") {
        } else {
          // getPatientDashboardByEmailAPI(res.data.email).then((res) => {
          //   // Assume that only one therapist is assigned to any patient.
          //   dispatch(
          //     updatePatientDashboard({
          //       therapyId: res.data.therapy_id,
          //       therapyStatus: res.data["therapy.status"],
          //       therapist: {
          //         name: res.data.name,
          //         designation: "Clinical Psychologist",
          //         profile: res.data.profile_url,
          //         rating: res.data.rating,
          //         connected_since: new Date().toISOString(),
          //         contact: res.data.contact_number,
          //         email: res.data.email,
          //       },
          //       upcoming_session: {
          //         sessionId: res.data.session_id,
          //         startDatetime: res.data.start_datetime_iso,
          //         endDatetime: res.data.end_datetime_iso,
          //         meetingLink: res.data.meeting_link,
          //       },
          //       wellbeing_status: 0, // Positive, Mixed and Distressed
          //       tasks: [],
          //     })
          //   );
          // });
        }
        navigate("/therapy_sessions");
      });
    }
  }, [authStatus, user]);

  const logOut = () => {
    signOut();
    dispatch(logoutResetState());
    navigate("/"); // reset the url after logging out
  };

  return (
    <>
      {authStatus === "authenticated" && !!patient_email ? (
        <App logOut={logOut} />
      ) : authStatus === "configuring" ||
        (authStatus === "authenticated" && !patient_email) ? (
        <div className="ring-loader">
          <ThreeDots
            height="100"
            width="100"
            color="#5F6CE1"
            radius="50"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="rings-loading"
          />
        </div>
      ) : (
        <PatientAuthPage />
      )}
    </>
  );
}

export default PatientAuthApp;
