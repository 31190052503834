/* eslint-disable react/prop-types */
import { useState } from "react";

const FullPagePopup = ({ text }) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    isOpen && (
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "rgba(255, 255, 255, 0.1)",
          backdropFilter: "blur(5px)",
        }}
      >
        <div
          style={{
            height: "fit-content",
            width: "600px",
            background: "white",
            borderRadius: "20px",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <div>
            {text.map((item, index) => {
              return (
                <p
                  key={index}
                  style={{
                    fontSize: "14px",
                    color: "#2C3254",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  {item}
                </p>
              );
            })}
          </div>
          <div
            style={{
              borderRadius: "8px",
              border: "1px solid rgba(44, 50, 84, 0.15)",
              background: "#F98959",
              color: "#FFF",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "12px",
              width: "fit-content",
              padding: "10px 20px",
              cursor: 'pointer',
              userSelect: 'none'
            }}
            onClick={() => setIsOpen(false)}
          >
            Okay
          </div>
        </div>
      </div>
    )
  );
};

export default FullPagePopup;
