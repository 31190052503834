import React, { useState } from "react";
import "./Invited.css";
import { useDispatch, useSelector } from "react-redux";
import { updateTherapyStatus } from "../../store/client/clientSlice";
import { ThreeDots } from "react-loader-spinner";
import { updateTherapyStatusAPI } from "../../api/patient";

const Invited = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const therapist = useSelector((store) => store.client.therapist);
  const therapyId = useSelector((store) => store.client.therapyId);

  const therapyStatus = useSelector((store) => store.client.therapyStatus);

  // Update Status in frontend and backend
  const updateTherapyStatusHandler = () => {
    // create loading
    setIsLoading(true);
    //call update api
    updateTherapyStatusAPI(therapyId, "ACTIVE")
      .then((res) => {
        // if success dispatch
        dispatch(updateTherapyStatus("ACTIVE"));
      })
      .catch((err) => {
        // if failed show message failed try again
        alert("Failed to update the status");
      });

    //stop loading
    setIsLoading(false);
  };

  return (
    <div className="invited-wrapper">
      <div className="invited-text">
        You are invited to the therapy by {therapist.name}. You can reach to
        your therapist on this following email {therapist.email}
      </div>
      {isLoading ? (
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#5F6CE1"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      ) : (
        <div
          className={`invited-button ${
            therapyStatus === "INVITED" ? "invite" : "invited"
          }`}
          onClick={updateTherapyStatusHandler}
        >
          {therapyStatus ? "Accept" : "Accepted"}
        </div>
      )}
    </div>
  );
};

export default Invited;
