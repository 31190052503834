import "./LastMeetingSummary.css";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  fetchLatestMeetingAIAnalysisAPI,
  fetchMeetingSummaryAPI,
} from "../../../../../api/meetingAIAnalysis";
import { format, parseISO } from "date-fns";
import { mysqlDatetimeToJavascriptDatetime } from "../../../../../utils/helpers";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function LastMeetingSummary() {
  const therapyId = useSelector((store) => store.client.therapyId);
  const [component, setComponent] = useState(0);
  const [summary, setSummary] = useState("");
  const [summaryDate, setSummaryDate] = useState("");

  useEffect(() => {
    fetchLatestMeetingAIAnalysisAPI(therapyId).then((res) => {
      if (res.length) {
        // meeting is available
        setSummaryDate(
          format(
            parseISO(mysqlDatetimeToJavascriptDatetime(res[0].start_time)),
            "EEE do MMM y"
          )
        );
        fetchMeetingSummaryAPI(res[0].video_id).then((data) => {
          setSummary(data?.summary);
          setComponent(1);
        });
      } else {
        setComponent(0);
      }
    });
  }, [therapyId]);

  // 0 means Rainy
  // 1 Sunny

  return (
    <div className="last-meeting-summary-status">
      <div className="last-meeting-summary-subheading">
        My Last Session Summary
      </div>
      {component === 1 ? (
        <div className="last-meeting-summary-content">
          <div>
            <div className="date-text">{summaryDate}</div>
            <div className="last-meeting-summary-content-text">{summary}</div>
          </div>
        </div>
      ) : (
        <div className="last-meeting-summary-content-placeholder">
          It will be available when your meeting has been analyzed
        </div>
      )}
    </div>
  );
}

export default LastMeetingSummary;
