/* eslint-disable react/display-name */
import React, {
  useState,
  useMemo,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { VRMLoaderPlugin, VRMUtils } from "@pixiv/three-vrm";
import { loadMixamoAnimation } from "../../utils/loadMixamoAnimation.js";

import { setAnimationLoaded, setModelLoadPercent } from "../../../../../store/avatar/avatarSlice.js";
import { useDispatch, useSelector } from "react-redux";

import greetAnimation from "../../../assets/animations/greet.fbx";
import talkingAnimation from "../../../assets/animations/talk/Talking.fbx";
import nodAnimation from "../../../assets/animations/headNodAnimation.fbx";

import { setGreetingsIsComplete, setIsSpeech, setRecordVideoButton } from "../../../../../store/avatar/avatarSlice.js";

const delay = (delayInms) => {
  return new Promise((resolve) => setTimeout(resolve, delayInms));
};

//https://forum.babylonjs.com/t/chatgpt-3d-talking-models/39801/3
export const AnimatableAvatar = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const isSpeech = useSelector(store => store.avatar.isSpeech);
  const userTranscript = useSelector((store) => store.avatar.userTranscript);
  const pushToTalkButton = useSelector((store) => store.avatar.pushToTalkButton);


  const { url, animationUrl, position, rotateModel } = props;
  const [vrm, setVrm] = useState(null);

  const idleAnimationRef = useRef({});
  const talkAnimationRef = useRef({});
  const greetAnimationRef = useRef({});
  const nodAnimationRef = useRef({});

  const isMounted = useRef({});

  const meshRef = useRef();

  const animationMixer = useMemo(() => {
    if (!animationUrl || !vrm) {
      return null;
    }

    const mixer = new THREE.AnimationMixer(vrm.scene);
    let nextAnimationUrl = animationUrl;

    const playNextAnimation = () => {
      if (nextAnimationUrl) {
        loadMixamoAnimation(nextAnimationUrl, vrm).then((clip) => {
          mixer.stopAllAction(); // Stop any currently playing animation.
          mixer.uncacheClip(clip); // Uncache the clip to avoid potential issues.
          const action = mixer.clipAction(clip);
          action.reset(); // Set the model to the rest pose before starting the next animation.
          action.enabled = true;
          nextAnimationUrl = null; // Reset the nextAnimationUrl after playing the next animation.
        });
      }
    };

    const onAnimationFinished = async () => {
      console.log("Called on animation finished", idleAnimationRef.current);
      if (Object.entries(idleAnimationRef.current).length === 0) {
        playNextAnimation();
      } else {
        // idleAnimationRef.current.play();
        greetAnimationRef.current.weight = 1;
        greetAnimationRef.current.repetitions = 100;
        // greetAnimationRef.current.play();
        await delay(1000);
        blendAnimation(5, greetAnimationRef.current, idleAnimationRef.current);
      }
      mixer.removeEventListener("finished", onAnimationFinished);
    };

    loadMixamoAnimation(greetAnimation, vrm).then((clip) => {
      const action = mixer.clipAction(clip);
      console.log("AnimationClip", action);
      // action.repetitions = 3; // Set the animation to play only once.
      // action.timeScale = 1;
      greetAnimationRef.current = action;
      // Set up the event listener to trigger the play of the next animation when the current one finishes.
      // mixer.addEventListener("finished", onAnimationFinished);

      loadMixamoAnimation(nextAnimationUrl, vrm).then(async (clip) => {
        dispatch(setAnimationLoaded(true));
        mixer.uncacheClip(clip); // Uncache the clip to avoid potential issues.
        const action = mixer.clipAction(clip);
        action.reset(); // Set the model to the rest pose before starting the next animation.
        idleAnimationRef.current = action;

        idleAnimationRef.current.weight = 0;
        greetAnimationRef.current.weight = 1;

        greetAnimationRef.current.play();
        idleAnimationRef.current.play();

        await delay(2000);
        await blendAnimation(7, greetAnimationRef.current, idleAnimationRef.current);
        isMounted.current && dispatch(setGreetingsIsComplete(true));
      });

      loadMixamoAnimation(nodAnimation, vrm).then((clip) => {
        mixer.uncacheClip(clip); // Uncache the clip to avoid potential issues.
        const action = mixer.clipAction(clip);
        action.reset(); // Set the model to the rest pose before starting the next animation.
        action.enabled = true;
        nodAnimationRef.current = action;
        nodAnimationRef.current.weight = 0;
        nodAnimationRef.current.play();
      });

      loadMixamoAnimation(talkingAnimation, vrm).then((clip) => {
        mixer.uncacheClip(clip); // Uncache the clip to avoid potential issues.
        const action = mixer.clipAction(clip);
        action.reset(); // Set the model to the rest pose before starting the next animation.
        action.enabled = true;
        talkAnimationRef.current = action;
        talkAnimationRef.current.weight = 0;
        talkAnimationRef.current.play();
      });
    });

    return mixer;
  }, [animationUrl, vrm]);

  const blendAnimation = async (delay, fromAnimation, toAnimation) => {
    return new Promise((resolve) => {
      let currentWeight = 1;
      let newWeight = 0;
      const intervalId = setInterval(() => {
        fromAnimation.weight = currentWeight;
        toAnimation.weight = newWeight;
        currentWeight -= 0.01;
        newWeight += 0.01;
        // console.log("The weights are: ", currentWeight, newWeight);

        if (newWeight > 1) {
          clearInterval(intervalId);
          resolve(); // Resolve the Promise to indicate animation completion
        }
      }, delay);
    });
  };

  const degrees_to_radians = (degrees) => {
    var pi = Math.PI;
    return degrees * (pi / 180);
  };

  // controls the speech animation
  useEffect(() => {
    if (Object.entries(idleAnimationRef.current).length !== 0) {
      if (isSpeech === true) {
        blendAnimation(5, idleAnimationRef.current, talkAnimationRef.current);
      } else if (isSpeech === false) {
        blendAnimation(5, talkAnimationRef.current, idleAnimationRef.current);
      }
    }
  }, [isSpeech]);

  // controls the listening animation 
  useEffect(() => {
    if (Object.entries(idleAnimationRef.current).length !== 0) {
      if (!pushToTalkButton && idleAnimationRef.current.weight < 0.1) {
        blendAnimation(5, nodAnimationRef.current, idleAnimationRef.current);
      } else if (userTranscript.length !== 0 && nodAnimationRef.current.weight < 0.1) {
        blendAnimation(5, idleAnimationRef.current, nodAnimationRef.current);
      }
    }
  }, [userTranscript, pushToTalkButton]);

  // Clean up the event listener when the component unmounts.
  useEffect(() => {
    return () => {
      if (animationMixer) {
        animationMixer.removeEventListener("finished");
      }
    };
  }, [animationMixer]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      dispatch(setIsSpeech(false));
      dispatch(setRecordVideoButton(false));
      dispatch(setGreetingsIsComplete(false));
    };
  }, []);

  useEffect(() => {
    if (!url) {
      return;
    }

    const loader = new GLTFLoader();
    loader.crossOrigin = "anonymous";

    loader.register((parser) => {
      return new VRMLoaderPlugin(parser, { autoUpdateHumanBones: true });
    });

    loader.load(
      url,
      (gltf) => {
        VRMUtils.removeUnnecessaryJoints(gltf.scene);
        const vrm = gltf.userData.vrm;

        if (!vrm) {
          return;
        }

        setVrm(vrm);
        VRMUtils.deepDispose(vrm.scene);

        vrm.scene.traverse((obj) => {
          obj.frustumCulled = false;
        });

        VRMUtils.rotateVRM0(vrm);
        console.log(vrm);
      },
      (progress) => {
        dispatch(setModelLoadPercent(100.0 * (progress.loaded / progress.total)));
        console.log(
          "Loading model...",
          100.0 * (progress.loaded / progress.total),
          "%"
        );
      },
      (error) => console.error(error)
    );
  }, [url]);

  useImperativeHandle(ref, () => ({ vrm, animationMixer }), [
    vrm,
    animationMixer,
  ]);

  useFrame((_, deltaTime) => {
    animationMixer?.update(deltaTime);
    vrm?.update(deltaTime);
  });

  if (meshRef.current) {
    // meshRef.current.rotation.y = 0.0174533; // Rotate by 45 degrees (adjust as needed)
  }

  if (vrm) {
    vrm.scene.children.forEach((mesh, i) => {
      mesh.castShadow = true;
      mesh.receiveShadow = true;
      mesh.rotation.y = degrees_to_radians(rotateModel);
    });
    // vrm.scene.castShadow = true;
    // vrm.scene.receiveShadow = true;
    vrm.scene.name = "IBYAvatar";
    // vrm.scene.name = 'Normalized_hips';
    return (
      <primitive
        ref={meshRef}
        castShadow
        receiveShadow
        position={position}
        object={vrm.scene}
      />
    );
  }

  // return vrm && <primitive object={vrm.scene} />
});
