import React from "react";
import { Link, useLocation } from "react-router-dom";
import ASSETS, { sidebar_bg } from "../../assets/Assets";
import "./Sidebar.css";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
export default function Sidebar() {
  const therapyId = useSelector((store) => store.client.therapyId);

  return (
    <div className="sidebar-wrapper">
      <img src={ASSETS.logo} className="sidebar-logo" alt="sidebar logo" />
      <div className="sidebar-menu">
        <img
          src={sidebar_bg}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          alt="sidebar background"
        />

        <PatientSideBar />
      </div>
    </div>
  );
}

const PatientSideBar = () => {
  const location = useLocation();
  const therapyId = useSelector((store) => store.client.therapyId);
  console.log("this is the therapy id: ", therapyId);

  return (
    <div className="z-index-high">
      <Tooltip
        title="Select a Therapist first to navigate to Dashboard"
        placement="right"
        disableHoverListener={therapyId.length===0?false:true}
      >
        <Link
          to={therapyId.length===0?"":"/dashboard"}
          style={{ textDecoration: "none" }}
          className={therapyId.length === 0 ? `disabled-element` : ``}
        >
          <div
            className={
              location.pathname !== "/dashboard"
                ? "sidebar-tab"
                : "sidebar-tab active"
            }
          >
            {location.pathname !== "/dashboard" ? (
              <img
                className="sidebar-icon"
                src={ASSETS.dashboardWhite}
                alt="dashboard white"
              />
            ) : (
              <img
                className="sidebar-icon"
                src={ASSETS.dashboardBlue}
                alt="dashboard blue"
              />
            )}
          </div>
        </Link>
      </Tooltip>

      <Tooltip
        title="Select a Therapist first to navigate to Reports"
        placement="right"
        disableHoverListener={therapyId.length===0?false:true}
      >
        <Link
          to={therapyId.length===0?"":"/report"}
          style={{ textDecoration: "none" }}
          className={therapyId.length === 0 ? `disabled-element` : ``}
        >
          <div
            className={
              !location.pathname.includes("/report")
                ? "sidebar-tab"
                : "sidebar-tab active"
            }
          >
            {!location.pathname.includes("/report") ? (
              <img
                className="sidebar-icon"
                src={ASSETS.clientReportWhite}
                alt="client report white"
              />
            ) : (
              <img
                className="sidebar-icon"
                src={ASSETS.clientReportBlue}
                alt="client report blue"
              />
            )}
          </div>
        </Link>
      </Tooltip>

      <Link to="/therapy_sessions" style={{ textDecoration: "none" }}>
        <div
          className={
            location.pathname !== "/therapy_sessions" ? "sidebar-tab" : "sidebar-tab active"
          }
        >
          {location.pathname !== "/therapy_sessions" ? (
            <img
              className="sidebar-icon"
              src={ASSETS.descriptionWhite}
              alt="Therapists white"
            />
          ) : (
            <img
              className="sidebar-icon"
              src={ASSETS.descriptionBlue}
              alt="Therapists blue"
            />
          )}
        </div>
      </Link>

      <Link to="/settings?tab=profile" style={{ textDecoration: "none" }}>
        <div
          className={
            location.pathname !== "/settings"
              ? "sidebar-tab"
              : "sidebar-tab active"
          }
        >
          {location.pathname !== "/settings" ? (
            <img
              className="sidebar-icon"
              src={ASSETS.settingsWhite}
              alt="settings white"
            />
          ) : (
            <img
              className="sidebar-icon"
              src={ASSETS.settingsBlue}
              alt="settings blue"
            />
          )}
        </div>
      </Link>
    </div>
  );
};
