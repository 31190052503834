import "./TherapistHeader.css";

function TherapistHeader() {
  return (
    <>
      <div>
        <div className="heading">Therapy Sessions</div>
        <div className="info-text client-info-text">
          You can check Therapists List overview here
        </div>
      </div>
    </>
  );
}

export default TherapistHeader;
