import ASSETS from "../../../assets/Assets";
import "./TherapistInfoTile.css";
import { useLocation, Link, useNavigate } from "react-router-dom";
import TimeAgo from "react-timeago";
import { useEffect } from "react";
import { useState } from "react";
import { readUserFilesURL } from "../../../api/s3";
import { mysqlDatetimeToJavascriptDatetime } from "../../../utils/helpers";

import { useDispatch, useSelector } from "react-redux";
import {
  updateTherapyStatus,
  updatePatientDashboard,
} from "../../../store/client/clientSlice";
import {
  updateTherapyStatusAPI,
  getPatientDashboardByTherapyIdAPI,
} from "../../../api/patient";

const TherapistInfoTile = function ({
  last_session_date,
  invited_date,
  therapist_id,
  therapy_id,
  name,
  designation,
  profile_photo_url,
  therapist_accepted,
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const therapyId = useSelector((store) => store.client.therapyId);
  const [isLoading, setIsLoading] = useState(false);

  const patientImage = profile_photo_url
    ? profile_photo_url
    : `https://ui-avatars.com/api/?name=${name}&background=random&rounded=true`;

  const handleTherapistClick = () => {
    navigate("/dashboard?therapy_id=" + encodeURIComponent(therapy_id));
  };

  // Update Status in frontend and backend
  const updateTherapyStatusHandler = () => {
    // create loading
    setIsLoading(true);
    //call update api
    updateTherapyStatusAPI(therapy_id, "ACTIVE")
      .then((res) => {
        // if success dispatch
        dispatch(updateTherapyStatus("ACTIVE"));

        // update the redux state and redirect
        handleTherapistClick();
      })
      .catch((err) => {
        // if failed show message failed try again
        alert("Failed to update the status");
      });

    //stop loading
    setIsLoading(false);
  };

  return (
    <div className="client-info-tile">
      <div className="info-list">
        {therapist_accepted ? (
          <>
            <div className="tile-header-text">Last Session Date</div>
            <div className="tile-header-info">
              {last_session_date === null ? (
                "Initial Session Pending"
              ) : (
                <TimeAgo date={last_session_date} />
              )}
            </div>
          </>
        ) : (
          <>
            <div className="tile-header-text">Invitation Date</div>
            <div className="tile-header-info">
              <TimeAgo date={mysqlDatetimeToJavascriptDatetime(invited_date)} />
            </div>
          </>
        )}
        <div className="details-container">
          <img
            src={patientImage}
            alt="profile_pic"
            className="client-image-clients-tab"
          />
          <div className="name-status-container">
            <div>
              <div className="client-tile-name">{name}</div>
              <div className="client-tile-designation">{designation}</div>
            </div>
          </div>
        </div>
        {therapist_accepted ? (
          <div className="more-info-button" onClick={handleTherapistClick}>
            <div className="more-info-text">Dashboard</div>
            <img src={ASSETS.orangeInfoArrow} alt="" />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              marginRight: "20px",
              marginTop: "10px",
            }}
          >
            <div
              className="button-glass button-accept"
              onClick={updateTherapyStatusHandler}
            >
              Accept
            </div>
            {/* <div className="button-glass button-reject">Reject</div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default TherapistInfoTile;
