import React from "react";
import "./AssessmentList.css";
import AssessmentItem from "../AssessmentItem/AssessmentItem";

const AssessmentList = ({ assessments, setPanel, setAssessmentId }) => {
  return (
    <div className="assessment-list-container">
      {assessments.map((item) => {
        return (
          <AssessmentItem
            assessment={item}
            setPanel={setPanel}
            setAssessmentId={setAssessmentId}
          />
        );
      })}
    </div>
  );
};

export default AssessmentList;
