import React from "react";
import "./AssessmentItem.css";
import ASSETS from "../../../../../../assets/Assets";

const AICheckInItem = ({ assessment, setPanel, setAssessmentId }) => {
  const handlerOnClick = () => {
    setPanel(2);
    setAssessmentId(assessment["assessment_id"]);
  };
  return (
    <div
      key={assessment["assessment_id"]}
      className={
        assessment["status"] === "missed"
          ? "assessment-item assessment-item-missing"
          : "assessment-item"
      }
      onClick={handlerOnClick}
    >
      <img
        className="assessement-item-image"
        src={ASSETS.robotSmile}
        alt="check in status"
      />
      <div className="assessement-item-label">Check In Status </div>
      <div className="assessement-item-description">
        (Ai Avatar Conversation)
      </div>
      <div className="assessement-item-take-action">
        <div className="assessement-item-take-action-date">
          {assessment["status"] === "missed" ? (
            <img src={ASSETS.missed_date} alt="date" />
          ) : (
            <img src={ASSETS.upcoming_date} alt="date" />
          )}
          <div
            className={
              assessment["status"] === "missed"
                ? "assessement-item-take-action-date-missed"
                : ""
            }
          >
            {" "}
            {new Date(assessment["due_date"]).toString().slice(4, 16)}
          </div>
        </div>
        <div
          className={
            assessment["status"] === "missed"
              ? "assessement-item-take-action-button assessement-item-take-action-button-missed"
              : "assessement-item-take-action-button"
          }
        >
          Take Now
        </div>
      </div>
    </div>
  );
};

const PHQ9Item = ({ assessment, setPanel, setAssessmentId }) => {
  const handlerOnClick = () => {
    setPanel(1);
    setAssessmentId(assessment["assessment_id"]);
  };
  return (
    <div
      key={assessment["assessment_id"]}
      className={
        assessment["status"] === "missed"
          ? "assessment-item assessment-item-missing"
          : "assessment-item"
      }
      onClick={handlerOnClick}
    >
      <img
        className="assessement-item-image"
        src={ASSETS.assessment}
        alt="phq 9"
      />
      <div className="assessement-item-label">PHQ 9</div>
      <div className="assessement-item-description">
        (Patient Health Questionnaire)
      </div>
      <div className="assessement-item-take-action">
        <div className="assessement-item-take-action-date">
          {assessment["status"] === "missed" ? (
            <img src={ASSETS.missed_date} alt="date" />
          ) : (
            <img src={ASSETS.upcoming_date} alt="date" />
          )}
          <div
            className={
              assessment["status"] === "missed"
                ? "assessement-item-take-action-date-missed"
                : ""
            }
          >
            {" "}
            {new Date(assessment["due_date"]).toString().slice(4, 16)}
          </div>
        </div>
        <div
          className={
            assessment["status"] === "missed"
              ? "assessement-item-take-action-button assessement-item-take-action-button-missed"
              : "assessement-item-take-action-button"
          }
        >
          Take Now
        </div>
      </div>
    </div>
  );
};

const GAD7Item = ({ assessment, setPanel, setAssessmentId }) => {
  const handlerOnClick = () => {
    setPanel(1);
    setAssessmentId(assessment["assessment_id"]);
  };
  return (
    <div
      key={assessment["assessment_id"]}
      className={
        assessment["status"] === "missed"
          ? "assessment-item assessment-item-missing"
          : "assessment-item"
      }
      onClick={handlerOnClick}
    >
      <img
        className="assessement-item-image"
        src={ASSETS.assessment}
        alt="check in status"
      />
      <div className="assessement-item-label">GAD 7</div>
      <div className="assessement-item-description">
        (Generalised Anxiety Disorder Assessment)
      </div>
      <div className="assessement-item-take-action">
        <div className="assessement-item-take-action-date">
          {assessment["status"] === "missed" ? (
            <img src={ASSETS.missed_date} alt="date" />
          ) : (
            <img src={ASSETS.upcoming_date} alt="date" />
          )}
          <div
            className={
              assessment["status"] === "missed"
                ? "assessement-item-take-action-date-missed"
                : ""
            }
          >
            {" "}
            {new Date(assessment["due_date"]).toString().slice(4, 16)}
          </div>
        </div>
        <div
          className={
            assessment["status"] === "missed"
              ? "assessement-item-take-action-button assessement-item-take-action-button-missed"
              : "assessement-item-take-action-button"
          }
        >
          Take Now
        </div>
      </div>
    </div>
  );
};

const AssessmentItem = ({ assessment, setPanel, setAssessmentId }) => {
  return assessment["assessment_type"] === "PHQ9" ? (
    <PHQ9Item
      assessment={assessment}
      setPanel={setPanel}
      setAssessmentId={setAssessmentId}
    />
  ) : assessment["assessment_type"] === "GAD7" ? (
    <GAD7Item
      assessment={assessment}
      setPanel={setPanel}
      setAssessmentId={setAssessmentId}
    />
  ) : (
    <AICheckInItem
      assessment={assessment}
      setPanel={setPanel}
      setAssessmentId={setAssessmentId}
    />
  );
};

export default AssessmentItem;
