import { useState, useEffect, useRef, Suspense } from "react";
import { AnimatableAvatar } from "../components/Avatar/AnimatableAvatar";
import { useFrame } from "@react-three/fiber";
import { useSelector } from "react-redux";

export default function AvatarScene({
  animation,
  position,
  talk,
  followMouse,
  rotateModel,
  model,
}) {
  const isSpeech = useSelector((store) => store.avatar.isSpeech);
  const pushToTalkButton = useSelector((store) => store.avatar.pushToTalkButton);

  const [modelUrl, setModelUrl] = useState(model);
  const [animationUrl, setAnimationUrl] = useState(animation);
  const animationAvatarRef = useRef();
  const [mousePos, setMousePos] = useState({
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  });
  const [rotateState, setRotateState] = useState(rotateModel);

  // drag and drop feature to change the model and the animation file
  useEffect(() => {
    function handleDragOver(event) {
      event.preventDefault();
    }

    function handleDrop(event) {
      event.preventDefault();

      const files = event.dataTransfer.files;
      if (!files) return;

      const file = files[0];
      if (!file) return;

      const fileType = file.name.split(".").pop();
      const blob = new Blob([file], { type: "application/octet-stream" });
      const url = URL.createObjectURL(blob);

      if (fileType === "fbx") {
        setAnimationUrl(url);
      } else {
        setModelUrl(url);
      }
    }

    window.addEventListener("dragover", handleDragOver);
    window.addEventListener("drop", handleDrop);

    return () => {
      window.removeEventListener("dragover", handleDragOver);
      window.removeEventListener("drop", handleDrop);
    };
  }, []);

  // mouse movement listener to get the mouse position coordinates
  // useEffect(() => {
  //   const handleMouseMove = (event) => {
  //     setMousePos({ x: event.clientX, y: event.clientY });
  //   };

  //   document
  //     .getElementById("canvas")
  //     .addEventListener("mousemove", handleMouseMove);

  //   return () => {
  //     document
  //       .getElementById("canvas")
  //       .removeEventListener("mousemove", handleMouseMove);
  //   };
  // }, []);

  useEffect(() => {
    setAnimationUrl(animation);
  }, [animation]);

  const mapValue = (value, minFrom, maxFrom, minTo, maxTo) => {
    return minTo + ((maxTo - minTo) * (value - minFrom)) / (maxFrom - minFrom);
  };

  // makes the character talk
  useFrame(({ clock }, delta) => {
    if (!animationAvatarRef.current?.vrm) {
      return;
    }

    const { vrm } = animationAvatarRef.current;
    const blinkSpeed = 3; // Speed of mouth movement

    // Calculate the blink rotation based on the current time
    const MOUTH_MIN = -1;
    const MOUTH_MAX = 1;
    const REDUCE_MOUTH_MOVEMENT = 0.5; // 1 is no movement during speech and 0 is most movement during speech
    const mouthMovement = mapValue(
      Math.sin(clock.elapsedTime * blinkSpeed * Math.PI * 2),
      MOUTH_MIN,
      MOUTH_MAX,
      MOUTH_MIN + REDUCE_MOUTH_MOVEMENT,
      MOUTH_MAX - REDUCE_MOUTH_MOVEMENT,
    );

    // change the default T pose
    customAvatarDefaultPosition(vrm);

    // follow the eyes with mouse position if enabled
    if (followMouse) {
      // moveEyesWithMouse(vrm);

      // Calculate the time since the last eye movement
      const eyeMovementDelay = 1.5;
      const elapsedSinceLastEyeMovement =
        clock.elapsedTime % (eyeMovementDelay + 0.1);
      if (elapsedSinceLastEyeMovement >= eyeMovementDelay + 0.08) {
        moveEyesRandom(vrm);
      }
    }

    // Smoothly transition the rotation
    const adjustValue = -30;
    const targetRotation = isSpeech ? rotateModel + adjustValue : rotateModel; // Define the target rotation based on isSpeech
    const rotationSpeed = 10; // Adjust this value to control the rotation speed 10

    const diff = targetRotation - rotateState;
    const deltaRotation = diff * rotationSpeed * delta;
    setRotateState(rotateState + deltaRotation);

    if (isSpeech) {
      // talk
      vrm.expressionManager.setValue("aa", mouthMovement);
      vrm.expressionManager.setValue("happy", 1);
      vrm.expressionManager.setValue("relaxed", 0.2);
    } else {
      vrm.expressionManager.setValue("relaxed", 0);
      vrm.expressionManager.setValue("happy", 0);

      vrm.expressionManager.setValue("aa", 0);
      vrm.expressionManager.setValue("ee", 0);
      vrm.expressionManager.setValue("ih", 0);
      vrm.expressionManager.setValue("oh", 0);
      vrm.expressionManager.setValue("ou", 0);
    }

    if(pushToTalkButton) {
      vrm.expressionManager.setValue("angry", 0.5);
    } else {
      vrm.expressionManager.setValue("angry", 0);
    }
  });

  // makes the character blink to look natural
  useFrame(({ clock }, delta) => {
    if (!animationAvatarRef.current?.vrm) {
      return;
    }

    const { vrm } = animationAvatarRef.current;
    const blinkDuration = 0.2; // Duration of the blink animation (in seconds)
    const blinkSpeed = 3; // Speed of the blink animation (higher value = faster blink)
    const blinkDelay = 4; // Delay between blinks (in seconds)

    // Calculate the blink rotation based on the current time
    const blinkRotation = Math.sin(
      clock.elapsedTime * blinkSpeed * Math.PI * 2
    );

    // Calculate the time since the last blink
    const elapsedSinceLastBlink = clock.elapsedTime % blinkDelay;

    // Check if enough time has passed to perform a blink
    if (elapsedSinceLastBlink <= blinkDuration) {
      // Set the rotation of the eye bones to create blinking effect
      vrm.expressionManager.setValue("blink", blinkRotation);
    } else {
      // Reset the rotation of the eye bones
      vrm.expressionManager.setValue("blink", 0);
    }
  });

  const map = (x, in_min, in_max, out_min, out_max) => {
    return ((x - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
  };

  const getRandomNumberInRange = (min, max) => {
    // Generate a random number between min (inclusive) and max (inclusive)
    return Math.random() * (max - min) + min;
  };

  const moveEyesRandom = (vrm) => {
    const randomValue = getRandomNumberInRange(-0.07, 0.07);

    vrm.humanoid.getNormalizedBoneNode("leftEye").rotation.y = randomValue;
    vrm.humanoid.getNormalizedBoneNode("rightEye").rotation.y = randomValue;

    // vrm.humanoid.getNormalizedBoneNode("leftEye").rotation.x = randomValue;
    // vrm.humanoid.getNormalizedBoneNode("rightEye").rotation.x = randomValue;
  };

  const moveEyesWithMouse = (vrm) => {
    vrm.humanoid.getNormalizedBoneNode("leftEye").rotation.y = map(
      mousePos.x,
      390,
      1500,
      -0.1,
      0.1
    );
    vrm.humanoid.getNormalizedBoneNode("rightEye").rotation.y = map(
      mousePos.x,
      390,
      1500,
      -0.1,
      0.1
    );

    vrm.humanoid.getNormalizedBoneNode("leftEye").rotation.x = map(
      mousePos.y,
      0,
      850,
      0.05,
      -0.05
    );
    vrm.humanoid.getNormalizedBoneNode("rightEye").rotation.x = map(
      mousePos.y,
      0,
      850,
      0.05,
      -0.05
    );
  };

  const customAvatarDefaultPosition = (vrm) => {
    // left shoulder
    vrm.humanoid.getNormalizedBoneNode("leftShoulder").rotation.x =
      -0.009758731102810609;
    vrm.humanoid.getNormalizedBoneNode("leftShoulder").rotation.y =
      -0.3364233701420146;
    vrm.humanoid.getNormalizedBoneNode("leftShoulder").rotation.z =
      0.1628130354127912;
    // left upper arm
    vrm.humanoid.getNormalizedBoneNode("leftUpperArm").rotation.x =
      -0.127516015542295;
    vrm.humanoid.getNormalizedBoneNode("leftUpperArm").rotation.y =
      0.09017354200294019;
    vrm.humanoid.getNormalizedBoneNode("leftUpperArm").rotation.z = 0.8;
    // left lower arm
    vrm.humanoid.getNormalizedBoneNode("leftLowerArm").rotation.x =
      0.04353967260382238;
    vrm.humanoid.getNormalizedBoneNode("leftLowerArm").rotation.y =
      -0.3926199795140142;
    vrm.humanoid.getNormalizedBoneNode("leftLowerArm").rotation.z =
      0.30865992977518575;
    // left hand
    vrm.humanoid.getNormalizedBoneNode("leftHand").rotation.x = 0;
    vrm.humanoid.getNormalizedBoneNode("leftHand").rotation.y = 0;
    vrm.humanoid.getNormalizedBoneNode("leftHand").rotation.z = 0.3;

    // right shoulder
    vrm.humanoid.getNormalizedBoneNode("rightShoulder").rotation.x =
      0.009758731102810609;
    vrm.humanoid.getNormalizedBoneNode("rightShoulder").rotation.y =
      0.3364233701420146;
    vrm.humanoid.getNormalizedBoneNode("rightShoulder").rotation.z =
      -0.1628130354127912;
    // right upper arm
    vrm.humanoid.getNormalizedBoneNode("rightUpperArm").rotation.x =
      0.127516015542295;
    vrm.humanoid.getNormalizedBoneNode("rightUpperArm").rotation.y =
      -0.09017354200294019;
    vrm.humanoid.getNormalizedBoneNode("rightUpperArm").rotation.z = -0.8;
    // right lower arm
    vrm.humanoid.getNormalizedBoneNode("rightLowerArm").rotation.x =
      -0.04353967260382238;
    vrm.humanoid.getNormalizedBoneNode("rightLowerArm").rotation.y =
      0.3926199795140142;
    vrm.humanoid.getNormalizedBoneNode("rightLowerArm").rotation.z =
      -0.30865992977518575;
    // right hand
    vrm.humanoid.getNormalizedBoneNode("rightHand").rotation.z = -0.3;
  };

  return (
    <Suspense fallback={null}>
      <AnimatableAvatar
        position={position}
        url={model}
        animationUrl={animationUrl}
        ref={animationAvatarRef}
        rotateModel={rotateState}
      />
    </Suspense>
  );
}
