import ASSETS from "../../../../assets/Assets";
import "./EditSessionSummaryModal.css";

function EditSessionSummaryModal({show, onClose}) {
  if (!show) return null;

  return <div className="modal" onClick={onClose}>
    <div className="modal-content edit-session-summary-modal-content" onClick={e => e.stopPropagation()}>
      <span className="close-modal-btn">
        <img src={ASSETS.modalCloseIcon} alt="close modal" onClick={onClose} />
      </span>

      <div className="modal-header edit-session-summary-modal-header">
        <div className="modal-title">
          <div className="modal-heading">Edit Session Summary</div>
        </div>
      </div>

      <div className="modal-body edit-session-summary-modal-body">
        <textarea className="edit-session-modal-summary">
          Based on the AI analysis of the session with Juhi, today's discussion primarily focused on addressing her work-related stress and its impact on her overall well-being. The analysis revealed that Juhi has been experiencing high levels of stress and is showing signs of burnout. The session aimed to explore the underlying causes of her stress, develop effective stress management techniques, and promote a healthier work-life balance.

          During the session, Juhi expressed feelings of overwhelm and shared specific challenges she faces in her professional life. The AI analysis identified key factors contributing to her stress, such as excessive workload, lack of boundaries, and ineffective communication in the workplace. Strategies were discussed to help Juhi cope with stress and regain control over her work environment.

          Next Session Planning -

          Based on your conversation in the session with Juhi, the following focus areas, tasks, and suggestions were discussed for the next session:
          1. Review Progress:
          Evaluate the effectiveness of the stress management techniques implemented since the last session.
          Discuss any challenges or successes encountered and identify areas that require further attention.
          2. Identifying Triggers:
          Explore specific triggers that contribute to Juhi's stress at work.
          Analyse patterns and develop strategies to manage these triggers effectively.
          Boundary Setting and Communication:
          Continue exploring strategies for setting clear boundaries and improving communication skills in the workplace.
          Role-play challenging scenarios and practice assertiveness techniques.
          3. Work-Life Balance Check-In:
          Assess the progress made in achieving a healthier work-life balance.
          Identify areas that need improvement and develop strategies to maintain balance.
        </textarea>

        <div className="edit-session-summary-modal-save-btn" onClick={onClose}>Save</div>
      </div>
    </div>
  </div>
}

export default EditSessionSummaryModal;