import { useState, useContext, useEffect, useRef } from "react";
import { Col, Row, Spin } from "antd";
import { Navigate, useLocation } from "react-router-dom";

import { getTimeDiffFromCurrent } from "../../utils/time";
import { getLatestPhq, getChatHistoryOfUser } from "../../controller/FetchAuth";

import "./AvatarPage.css";
import DisplayAvatar from "../../components/Avatar/DisplayAvatar";
import FullPagePopup from "../../components/General/FullPagePopup";

import SpeechToText from "../../utils/SpeechToText";
import RecordVideo from "../../components/Video/RecordVideo";
import ButtonGroup from "../../components/General/ButtonGroup";
// svg icon components

import { useSelector, useDispatch } from "react-redux";
import {
  setUserTranscript,
  setLlmTranscript,
  setRecordVideoButton,
  setStartConversation,
  setIsSpeech,
} from "../../../../../store/avatar/avatarSlice";

import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

// import modelvrm from "../../../assets/models/model2.vrm";
import modelvrm from "../../../assets/models/Japanese 3d_model.vrm";
import idleAnimation from "../../../assets/animations/idle/idle-1.fbx";

import chatOff from "../../../assets/icons/chat_off.svg";
import chatOn from "../../../assets/icons/chat_on.svg";
import videoOff from "../../../assets/icons/videocam_off.svg";
import videoOn from "../../../assets/icons/videocam_on.svg";
import endConversation from "../../../assets/icons/end_conversation.svg";

const QUESTIONNAIRE_INTERVAL_MINUTES = 20160;

const SHOW_TIMER_INFO = false;

const App = ({ customStyle, assessmentId }) => {
  const dispatch = useDispatch();

  const [animation, setAnimation] = useState(idleAnimation);
  const [model, setModel] = useState(modelvrm);
  const [selectedSynth, setSelectedSynth] = useState(null);
  const [transcript, setTranscript] = useState("");
  const [botResponse, setBotResponse] = useState("");
  const [conversationMode, setConversationMode] = useState(0); // 0=video, 1=chat, 2=audio, 3=video playback
  const [videoPermission, setVideoPermission] = useState(true);
  const [record, setRecord] = useState(false);

  const [video, setVideo] = useState("");
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [loading, setLoading] = useState(false);

  const [chatHistory, setChatHistory] = useState([]);

  // const userTranscript = useSelector((store) => store.avatar.userTranscript);
  // const llmTranscript = useSelector((store) => store.avatar.llmTranscript);
  const recordVideoButton = useSelector(
    (store) => store.avatar.recordVideoButton
  );
  const modelLoadPercent = useSelector(
    (store) => store.avatar.modelLoadPercent
  );
  const animationLoaded = useSelector((store) => store.avatar.animationLoaded);

  const isSpeech = useSelector((store) => store.avatar.isSpeech);

  useEffect(() => {
    console.log(
      "From the avatar page: ",
      parseFloat(modelLoadPercent).toFixed(2)
    );
  }, [modelLoadPercent]);

  const pauseSpeechButtonRef = useRef(null);

  // parseInt(import.meta.env.VITE_IDLE_LIMIT, 10)
  // parseInt(import.meta.env.VITE_IDLE_NOTIFY_REMAINING_THRESHOLD, 10)
  // parseInt(import.meta.env.VITE_SESSION_LIMIT, 10)

  const sessionIntervalRef = useRef(null);
  const sessionTimeoutRef = useRef(null);
  const SESSION_LIMIT = parseInt(20, 10) * 60 * 1000; // in minutes
  const [remainingSessionTime, setRemainingSessionTime] = useState(
    SESSION_LIMIT / 1000
  );
  let sessionTimer;

  const idleTimeoutRef = useRef(null);
  const idleIntervalRef = useRef(null);
  const IDLE_LIMIT = parseInt(2, 10) * 60 * 1000; // in minutes
  const IDLE_NOTIFY_REMAINING_THRESHOLD = parseInt(1, 10) * 60; // minutes
  const [remainingIdleTime, setRemainingIdleTime] = useState(IDLE_LIMIT / 1000);
  let idleTimer;

  const getRemainingTime = (timer, limit) => {
    if (timer) {
      const elapsedTime = Date.now() - timer;
      const remainingTime = Math.max(0, limit - elapsedTime);
      return remainingTime;
    }
    return 0; // Session has not started or already ended
  };

  function sessionTimerEndFunction() {
    dispatch(setStartConversation(false));
    setBotResponse("");
    setTranscript("");

    dispatch(setUserTranscript(""));
    dispatch(setLlmTranscript(""));

    clearInterval(sessionIntervalRef.current);
    clearInterval(idleIntervalRef.current);
    clearTimeout(idleTimeoutRef.current);

    setRemainingSessionTime(SESSION_LIMIT / 1000);
    setRemainingIdleTime(IDLE_LIMIT / 1000);
  }

  function idleTimerEndFunction() {
    dispatch(setStartConversation(false));
    setBotResponse("");
    setTranscript("");

    dispatch(setUserTranscript(""));
    dispatch(setLlmTranscript(""));

    clearInterval(idleIntervalRef.current);
    clearTimeout(sessionTimeoutRef.current);
    clearInterval(sessionIntervalRef.current);

    setRemainingSessionTime(SESSION_LIMIT / 1000);
    setRemainingIdleTime(IDLE_LIMIT / 1000);
  }

  const startSession = () => {
    console.log("Session Started");
    sessionTimeoutRef.current = setTimeout(
      sessionTimerEndFunction,
      SESSION_LIMIT
    );
    sessionTimer = Date.now();
    sessionIntervalRef.current = setInterval(() => {
      setRemainingSessionTime(
        Math.floor(getRemainingTime(sessionTimer, SESSION_LIMIT) / 1000)
      );
      console.log("session interval ran");
    }, 500);
  };

  const startIdleCount = () => {
    // if(idleTimeoutRef) clearTimeout(idleTimeoutRef.current)
    // if(idleIntervalRef) clearInterval(idleIntervalRef.current);

    // setRemainingIdleTime(IDLE_LIMIT / 1000);
    clearTimeout(idleTimeoutRef.current);
    clearInterval(idleIntervalRef.current);

    console.log("Idle Count Started");
    idleTimeoutRef.current = setTimeout(idleTimerEndFunction, IDLE_LIMIT);
    idleTimer = Date.now();
    idleIntervalRef.current = setInterval(() => {
      setRemainingIdleTime(
        Math.floor(getRemainingTime(idleTimer, IDLE_LIMIT) / 1000)
      );
      // console.log("idle interval ran");
    }, 500);
  };

  const endSession = () => {
    dispatch(setRecordVideoButton(false));
    dispatch(setStartConversation(false));
    setBotResponse("");
    setTranscript("");

    dispatch(setUserTranscript(""));
    dispatch(setLlmTranscript(""));

    clearInterval(sessionIntervalRef.current);
    clearTimeout(sessionTimeoutRef.current);
    setRemainingSessionTime(SESSION_LIMIT / 1000);

    clearInterval(idleIntervalRef.current);
    clearTimeout(idleTimeoutRef.current);
    setRemainingIdleTime(IDLE_LIMIT / 1000);

    dispatch(setIsSpeech(false));
    window.speechSynthesis.cancel();
  };

  const location = useLocation();
  useEffect(() => {
    const data = location.state;
    if (data !== null) {
      setModel(data.avatar);
      setSelectedSynth(data.voice);
      console.log("The state is from avatar page: ", data);
    }
  }, []);

  useEffect(() => {
    return () => {
      console.log("Closed the component");
      clearInterval(sessionIntervalRef.current);
      clearTimeout(sessionTimeoutRef.current);

      clearInterval(idleIntervalRef.current);
      clearTimeout(idleTimeoutRef.current);
    };
  }, []);

  const getChatHistory = async () => {
    // const body = {};
    // body.userId = Cookies.get("logged_in_userid");
    // const chatHistory = await getChatHistoryOfUser(
    //   body,
    //   Cookies.get("jwt_access_token")
    // );
    // setChatHistory(chatHistory.data.data.data);
  };

  useEffect(() => {
    getChatHistory();
  }, []);

  useEffect(() => {
    console.log("this ios the startConversation: ", recordVideoButton);
  }, [recordVideoButton]);

  const onChatHistoryClick = (link) => {
    console.log("Clicked", video);
    console.log("Clicked: ", 3);
    setConversationMode(3);
    setVideo(link);
  };

  const changeVideoPermission = (permission) => {
    setVideoPermission(permission);
  };

  const onBotResponse = (data) => {
    setBotResponse(data);
    dispatch(setLlmTranscript(data));
  };

  const onSpeechResult = (data) => {
    setTranscript(data);
    dispatch(setUserTranscript(data));
  };

  const onButtonGroupClick = (data) => {
    console.log("Clicked: ", data);
    setConversationMode(data);
  };

  const onNewConversationClick = () => {
    setConversationMode(0);
    console.log("Clicked new conversation button");
  };

  const onChatHistorySaveFinish = () => {
    // refresh the component to show the latest chat history
    getChatHistory();
  };

  const handleInputChange = (e) => {
    onSpeechResult(e.target.value)
  };

  // if not logged in, then navigate back to the auth page
  let isValidJWT = false;
  let token = Cookies.get("jwt_access_token");

  if (token !== undefined) {
    let decodedToken = jwtDecode(token);
    let currentDate = new Date();

    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      // console.log("Token expired.");
    } else {
      isValidJWT = true;
    }
  }

  // if (!isValidJWT) {
  //   return <Navigate to="/auth" replace />;
  // }

  // if (shouldNavigate) {
  //   console.log("should navigate");
  //   return <Navigate to="/questionnaire" replace />;
  // }

  if (loading) {
    // Return loading indicator or null if you don't want anything to render
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "#ababab",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading...
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        // contain: "strict",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          // justifyContent: "center",
          gap: "20px",
        }}
      >
        <div
          style={{
            // backgroundColor: "#888d8f",
            height: "100%",
            borderRadius: "40px 0px 0px 40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="avatar-camera-container">
            <div className="avatar-container">
              <DisplayAvatar
                followMouse={false}
                // avatarPosition={[0, -0.04, -0.05]}
                avatarPosition={[0, -0.4, -0.1]}
                style={{
                  // borderRadius: "40px",
                  // background: "#bdccde",
                }}
                animation={animation}
                gridHelper={false}
                // talk={talk}
                // cameraPosition={[0.05, 1.55, 1]}
                // cameraRotation={[-0.19, -0.01, 0]}
                cameraPosition={[0.05, 1.7, 1.3]}
                cameraRotation={[-0.34, -0.01, 0]}
                disableOrbitControl={false}
                rotateModel={8}
                model={model}
              />
            </div>

            <div className="camera-container">
              <div className="camera-wrapper">
                <div className="camera-main">
                  <RecordVideo

                    record={record}
                    setRecord={setRecord}
                    // permission={!videoPermission}
                    permission={videoPermission}
                    conversationMode={conversationMode}
                    startSession={startSession}
                    startIdleCount={startIdleCount}
                  />
                </div>
                <div className="camera-control-container">

                  {recordVideoButton ? (
                    <div className="camera-control-panel">
                      <button className={`camera-control-button ${conversationMode === 1 ? "camera-control-button-on" : ""}`}
                        onClick={() => {
                          changeVideoPermission(false);
                          setConversationMode(1);
                        }}
                      >
                        <img
                          className="camera-control-button-icon"
                          src={conversationMode === 1 ? chatOn : chatOff}
                          alt="chat mode icons"
                        />
                      </button>
                      <button className={`camera-control-button ${conversationMode === 0 ? "camera-control-button-on" : ""}`}
                        onClick={() => {
                          console.log("videoPermission", videoPermission)
                          changeVideoPermission(true);
                          setConversationMode(0);
                        }}
                      >
                        <img
                          className="camera-control-button-icon"
                          src={conversationMode === 0 ? videoOn : videoOff}
                          alt="video mode icons"
                        />
                      </button>

                      <button className="camera-control-button"
                        onClick={() => { endSession() }}
                      >
                        <img
                          className="camera-control-button-icon"
                          src={endConversation}
                          alt="endConversation icons"
                        />
                      </button>
                    </div>
                  ) : (
                    <div></div>
                  )
                  }
                  <SpeechToText
                    onResult={onSpeechResult}
                    onResponse={onBotResponse}
                    pauseSpeechButtonRef={pauseSpeechButtonRef}
                    onChatHistorySaveFinish={onChatHistorySaveFinish}
                    // record={!videoPermission}
                    record={videoPermission}
                    startIdleCount={startIdleCount}
                    selectedSynth={selectedSynth}
                    assessmentId={assessmentId}
                    conversationMode={conversationMode}
                  />
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "15%",
                  right: "5%",
                  cursor: "pointer",
                  // display: recordVideoButton ? "" : "none",
                  display: "none", /* Set it to none for now, in preparation for potentially displaying it in the future.  */
                }}
                onClick={() => {
                  endSession();
                }}
              >
                <p
                  style={{
                    color: "#FFF",
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    textAlign: "center",
                    margin: "0px",
                  }}
                >
                  End my Conversation
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              top: "5%",
              right: "0%",
              display: "flex",
              gap: "1.5rem",
              flexDirection: "column",
              backgroundColor: "red"
            }}
          ></div>
        </div>

        <div
          style={{
            height: "100px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: "none"
          }}
        >
          {recordVideoButton ? (
            <div
              className="avatar-transcripts"
              style={{
                display: "flex",
                width: "100%",
                height: "100%",

              }}
            >
              <div
                id="bot transcript"
                className="scroll-thin-vertical"
                style={{
                  flex: 1,
                  overflowY: "scroll",
                  overflowX: "none",
                  contain: "strict",
                }}
              >
                <p
                  style={{
                    opacity: 0.7,
                    fontWeight: "500",
                    color: "#2C3254",
                    margin: "0px",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    lineHeight: "22px",
                    letterSpacing: "0px",
                    textAlign: "center",
                  }}
                >
                  {botResponse}
                </p>
              </div>
              <div
                id="user transcript"
                className="scroll-thin-vertical"
                style={{
                  flex: 1,
                  overflowY: "scroll",
                  overflowX: "none",
                  // contain: "strict",
                }}
              >
                <textarea
                  style={{
                    width: "100%",
                    height: "100%",
                    opacity: 0.7,
                    fontWeight: "500",
                    color: "#2C3254",
                    margin: "0px",
                    fontFamily: "Roboto",
                    fontSize: "14px",
                    lineHeight: "22px",
                    letterSpacing: "0px",
                    border: "1px solid #28286026",
                    outline: "none",
                    resize: "none"

                  }}
                  onChange={handleInputChange}
                  value={transcript}
                  disabled={conversationMode !== 1}
                >
                </textarea>
              </div>
            </div>
          ) : (
            <div
              style={{
                borderRadius: "8px",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "21px",
                letterSpacing: "0em",
                textAlign: "center",
                padding: "10px 20px",
                background: "#5F6CE1",
                color: "#FFFFFF",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(setRecordVideoButton(true));
                dispatch(setStartConversation(true));
              }}
            >
              Start the Assessment
            </div>
          )}
        </div>
      </div>

      {remainingIdleTime <= IDLE_NOTIFY_REMAINING_THRESHOLD && (
        <FullPagePopup
          text={[
            `Seems like your conversation has been left idle for a while now.`,
            `Your conversation will end in ${IDLE_NOTIFY_REMAINING_THRESHOLD} seconds`,
          ]}
        />
      )}
      {!animationLoaded && (
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(255, 255, 255, 0.6)",
          }}
        >
          <Spin spinning={true} />
          {modelLoadPercent !== 100 ? (
            <span
              style={{
                marginLeft: "0.5rem",
                fontSize: "1rem",
                fontWeight: "600",
              }}
            >
              Loading Model ... {parseFloat(modelLoadPercent).toFixed(2)} %
            </span>
          ) : (
            <span
              style={{
                marginLeft: "0.5rem",
                fontSize: "1rem",
                fontWeight: "600",
              }}
            >
              Loading Animations ...
            </span>
          )}
        </div>
      )}
      {remainingIdleTime <= IDLE_NOTIFY_REMAINING_THRESHOLD && (
        <FullPagePopup
          text={[
            `Seems like your conversation has been left idle for a while now.`,
            `Your conversation will end in ${IDLE_NOTIFY_REMAINING_THRESHOLD} seconds`,
          ]}
        />
      )}
      {!animationLoaded && (
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(255, 255, 255, 0.6)",
          }}
        >
          <Spin spinning={true} />
          {modelLoadPercent !== 100 ? (
            <span
              style={{
                marginLeft: "0.5rem",
                fontSize: "1rem",
                fontWeight: "600",
              }}
            >
              Loading Model ... {parseFloat(modelLoadPercent).toFixed(2)} %
            </span>
          ) : (
            <span
              style={{
                marginLeft: "0.5rem",
                fontSize: "1rem",
                fontWeight: "600",
              }}
            >
              Loading Animations ...
            </span>
          )}
        </div>
      )}
      {SHOW_TIMER_INFO && (
        <>
          <div
            style={{
              position: "absolute",
              bottom: "10%",
              left: "30%",
              background: "#B899F6",
              color: "#fff",
              fontWeight: "500",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            {"Remaining Session Time: " + remainingSessionTime}
          </div>

          <div
            style={{
              position: "absolute",
              bottom: "5%",
              left: "30%",
              background: "#B809A6",
              color: "#fff",
              fontWeight: "500",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            {"Remaining Idle Time: " + remainingIdleTime}
          </div>
        </>
      )}
      {videoPermission && (
        <div className="videoPermissionNotification"
        >
          <div>
            <p>You are not recording your conversations!</p>
            <p>You will miss out on your PCR Score and incentives.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
