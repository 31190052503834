import { createSlice } from "@reduxjs/toolkit";

const avatarSlice = createSlice({
  name: "avatar",
  initialState: {
    userTranscript: "",
    llmTranscript: "",
    recordVideoButton: false,
    pushToTalkButton: false,

    isSpeech: false,
    modelLoadPercent: 0,
    animationLoaded: false,
    startConversation: false,
    video: null,
    greetingsIsComplete: false
  },
  reducers: {
    setUserTranscript: (state, action) => {
      state.userTranscript = action.payload;
      return state;
    },
    setLlmTranscript: (state, action) => {
      state.llmTranscript = action.payload;
      return state;
    },
    setRecordVideoButton: (state, action) => {
      state.recordVideoButton = action.payload;
      return state;
    },
    setIsSpeech: (state, action) => {
      state.isSpeech = action.payload;
      return state;
    },
    setModelLoadPercent: (state, action) => {
      state.modelLoadPercent = action.payload;
      return state;
    },
    setAnimationLoaded: (state, action) => {
      state.animationLoaded = action.payload;
      return state;
    },
    setStartConversation: (state, action) => {
      state.startConversation = action.payload;
      return state;
    },
    setVideo: (state, action) => {
      state.video = action.payload;
      return state;
    },
    setGreetingsIsComplete: (state, action) => {
      state.greetingsIsComplete = action.payload;
      return state;
    },
    setPushToTalkButton: (state, action) => {
      state.pushToTalkButton = action.payload;
      return state;
    },
  },
});

export const {
  setUserTranscript,
  setLlmTranscript,
  setRecordVideoButton,
  setIsSpeech,
  setModelLoadPercent,
  setAnimationLoaded,
  setStartConversation,
  setVideo,
  setGreetingsIsComplete,
  setPushToTalkButton
} = avatarSlice.actions;

export default avatarSlice.reducer;
