import { createSlice } from "@reduxjs/toolkit";

const clientSlice = createSlice({
  name: "client",
  initialState: {
    therapyId: "",
    therapyStatus: "",
    therapist: {
      profile: "",
      name: "",
      designation: "",
      rating: 0,
      connected_since: new Date().toISOString(),
      contact: "",
      email: "",
    },
    upcoming_session: {
      sessionId: null,
      startDatetime: null,
      endDatetime: null,
      meetingLink: null,
    },
    wellbeing_status: 0, // Positive, Mixed and Distressed
    tasks: {
      metrics: {
        total_tasks_given: 0,
        tasks_completed: 0,
        tasks_incomplete: 0,
      },
      recent: [],
    },
  },
  reducers: {
    updatePatientDashboard: (state, action) => {
      return action.payload;
    },
    updateRating: (state, action) => {
      state.therapist.rating = action.payload;
      return state;
    },
    updateTherapyStatus: (state, action) => {
      state.therapyStatus = action.payload;
      return state;
    },
  },
});

export const { updatePatientDashboard, updateRating, updateTherapyStatus } =
  clientSlice.actions;

export default clientSlice.reducer;
