import "./App.css";
import MindlyPatientApp from "./apps/MindlyPatientApp";

const MindlyApp = () => {
  return (
    <div className="app">
      <MindlyPatientApp />
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <MindlyApp />
    </div>
  );
}

export default App;
