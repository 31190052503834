import React from "react";
import "./SessionItem.css";
import { BsChevronRight } from "react-icons/bs";
import ASSETS from "../../../../assets/Assets";
import { add, format, parseISO } from "date-fns";
import { mysqlDatetimeToJavascriptDatetime } from "../../../../utils/helpers";
import { Link } from "react-router-dom";

const WELL_BEING = {
  1: ASSETS.sunnyGIF,
  0.5: ASSETS.partlyCloudyGIF,
  0: ASSETS.cloudyGIF,
};

const getWellBeingStatusImage = (well_being_status) => {
  if (well_being_status <= 0.3) {
    return 0;
  } else if (well_being_status <= 0.7 && well_being_status > 0.3) {
    return 0.5;
  } else {
    return 1;
  }
};

function SessionItem({ session }) {
  const formattedStartTime = format(
    parseISO(mysqlDatetimeToJavascriptDatetime(session.start_time)),
    "HH:mm"
  );
  const formattedEndTime = format(
    add(parseISO(mysqlDatetimeToJavascriptDatetime(session.start_time)), {
      seconds: session.duration,
    }),
    "HH:mm"
  );
  return (
    <Link
      to={
        session["status"] === "ANALYZED"
          ? "/report/ai-analysis/session?video_id=" + session["video_id"]
          : ""
      }
      className="session-link"
    >
      <div
        className="session-item-wrapper"
        style={{
          cursor: session.status === "ANALYZED" ? "pointer" : "not-allowed",
        }}
      >
        <div className="session-item-date-time" style={{
          borderRight: '4px rgba(40, 40, 96, 0.3) solid',
        }}>
          <div className="session-item-date">
            {format(
              parseISO(mysqlDatetimeToJavascriptDatetime(session.start_time)),
              "LLL d, yyyy"
            )}
          </div>
          <div className="session-item-time">
            {formattedStartTime} - {formattedEndTime}
          </div>
        </div>
        {/* <div className="session-item-under-weather-wrapper">
          <div className="session-item-under-weather-heading">
            Under the Weather Status
          </div>
          <div className="session-item-under-weather-icon">
            <img
              src={
                WELL_BEING[getWellBeingStatusImage(session.well_being_status)]
              }
              alt="wellbeing-status"
            />
          </div>
        </div> */}
        <div className="session-item-summary-wrapper">
          <div className="session-item-summary-heading">Session Summary</div>
          <div className="session-item-summary-body">{session.description}</div>
        </div>
        <div className="session-item-edit-icon-wrapper">
          <div className="session-item-left-arrow">
            <BsChevronRight />
          </div>
        </div>
      </div>
    </Link>
  );
}

export default SessionItem;
