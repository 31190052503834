import axios from "axios";
import config from "../utils/config";
import { Auth } from "aws-amplify";

export const getUserFilesUploadURL = async (patient_id, extension) => {
  const baseURL = config.API_URL;
  const path = `/patient_files?patient_id=${patient_id}&extension=${extension}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  const data = await axios.get(baseURL + path, {
    headers: {
      Authorization: idToken,
    },
  });
  return data;
};

export const uploadFiles = async (preSignedUrl, file) => {
  const data = await axios.put(preSignedUrl, file, {
    headers: {
      "Content-Type": file.type,
    },
  });

  return data;
};

export const readUserFilesURL = async (key) => {
  const baseURL = config.API_URL;
  const path = `/user_files?key=${key}`;
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();

  const res = await axios.get(baseURL + path, {
    headers: {
      Authorization: idToken,
    },
  });
  return res.data;
};
