/* eslint-disable react/prop-types */
// add another timer which will trigger with the start conversation button, but will reset each time with the TTS button click
import { useState, useEffect, useRef } from "react";
import getBlobDuration from "get-blob-duration";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
// import { v4 as uuidv4 } from 'uuid';

import {
  Chatgpt,
  saveChatHistory,
  generatePresignedUrl,
  updateVideoFileName,
} from "../controller/FetchAuth";

import { generateChatTitle, detectMobile } from "./utils";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import axios from "axios";
import ProgressBar from "../components/General/ProgressBar";
import RealtimeAiAssesement from "./RealtimeAiAssesement/RealtimeAiAssesement";

import {
  getSingleAssessmentAPI,
} from "../../../../api/assessments";

import {
  getLlmResponseForAvatar,
  createAvatarConversationSession,
  getPresignedUrlForAvatarSessionVideo,
  saveAvatarSession,
  getInitiateConversation,
} from "../../../../api/avatar";

import {
  setIsSpeech,
  setVideo,
  setPushToTalkButton,
} from "../../../../store/avatar/avatarSlice";
import { useDispatch, useSelector } from "react-redux";

import "../pages/AvatarPage/AvatarPage.css"

import close from "../../assets/icons/close.svg"

const REMEMBER_LENGTH = 30;

const PREFERRED_VOICES = {
  "ja-JP": "Kyoko",
};

let myTimeout;
function myTimer() {
  window.speechSynthesis.pause();
  window.speechSynthesis.resume();
  myTimeout = setTimeout(myTimer, 10000);
}

const VoiceToTextConverter = ({
  onResult,
  onResponse,
  pauseSpeechButtonRef,
  onChatHistorySaveFinish,
  record,
  startIdleCount,
  selectedSynth,
  assessmentId,
  conversationMode,
}) => {
  const dispatch = useDispatch();
  const { transcript, listening, resetTranscript } = useSpeechRecognition();

  const isSpeech = useSelector((store) => store.avatar.isSpeech);
  const video = useSelector((store) => store.avatar.video);
  const animationLoaded = useSelector((store) => store.avatar.animationLoaded);
  const greetingsIsComplete = useSelector(
    (store) => store.avatar.greetingsIsComplete
  );

  const userTranscript = useSelector((store) => store.avatar.userTranscript);

  const [button, setButtonState] = useState(false);

  const [pitch, setPitch] = useState(1);
  const [speed, setSpeed] = useState(0.95);
  const [voices, setVoices] = useState(null);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [chats, setChats] = useState([]);

  const [chatHistory, setChatHistory] = useState([]);

  const [initiateConversation, setInitiateConversation] = useState(null);
  const [language, setLanguage] = useState(navigator.language); // Use the browser's default language setting
  const [fetchedTherapyId, setFetchedTherapyId] = useState('');
  const [fetchedpPtientId, setFetchedpPtientId] = useState('');

  const [aiAvatarSessionId, setAiAvatarSessionId] = useState("");
  const [conversationId, setConversationId] = useState("");

  const therapy_id = useSelector((store) => store.client.therapyId);
  const patient_id = useSelector((store) => store.patient.patient_id);
  const recordVideoButton = useSelector(
    (store) => store.avatar.recordVideoButton
  );

  const resetUploadProgress = () => {
    console.log("resetUploadProgress")
    setUploadProgress(0);
  };

  const fetchTherapyId = async () => {
    const response = await getSingleAssessmentAPI(
      assessmentId
    );
    if (response.data.length > 0 && response.data[0].therapy_id && response.data[0].patient_id) {
      setFetchedTherapyId(response.data[0].therapy_id);
      setFetchedpPtientId(response.data[0].patient_id);
    }
  };

  useEffect(() => {
    console.log("transcript is :", transcript);
    button && onResult(transcript);
  }, [transcript]);

  // In cases without login, since there's no therapy_id in Redux, it will be retrieved via an API call.
  useEffect(() => {
    if (!therapy_id) {
      fetchTherapyId();
    } else {
      setFetchedTherapyId(therapy_id);
      setFetchedpPtientId(patient_id);
    }
  }, [therapy_id]);

  useEffect(() => {
    console.log("This is the chat history: ", chatHistory);
  }, [chatHistory]);

  useEffect(() => {
    console.log("This is the chat history: ", chatHistory);
  }, [chatHistory]);

  useEffect(() => {
    const fetchUploadUrl = async () => {
      const initiateConversation = await getInitiateConversation(
        fetchedTherapyId,
        language
      );
      console.log("initiateConversation", initiateConversation);
      setInitiateConversation(initiateConversation.data);
    };

    if (fetchedTherapyId && language) fetchUploadUrl();
  }, [fetchedTherapyId, language]);

  useEffect(() => {
    

    if (animationLoaded && greetingsIsComplete && initiateConversation && recordVideoButton) {
      utterWithAvatar(initiateConversation);
      (async () => {
        const response = await createAvatarConversationSession(fetchedpPtientId);
        console.log(
          "This is the response from createAvatarConversationSession : ",
          response
        );
        setAiAvatarSessionId(response.data.ai_avatar_session_id);
        setConversationId(response.data.conversation_id);
      })();
    }
    // utterWithAvatar(
    //   "Hello, I am your friend. Let's embark on a journey of self-discovery and growth together."
    // );
    

  }, [animationLoaded, greetingsIsComplete, initiateConversation, recordVideoButton]);

  // aggregate chats
  const aggregateMessage = (role, content, setChats, isHistory) => {
    const currentDate = new Date();
    const unixTimestampMilliseconds = currentDate.getTime();
    const unixTimestampSeconds = Math.floor(currentDate.getTime() / 1000);

    const newChat = {
      role: role,
      content: content,
    };

    if (isHistory) {
      newChat.chat_time = unixTimestampSeconds;
    }

    setChats((prevChats) => [...prevChats, newChat]);
    return [...chats, newChat];
  };

  const deleteChats = (position, elementCount) => {
    const newArray = [...chats];
    newArray.splice(position, elementCount); // Remove 2nd and 3rd elements
    setChats(newArray);
  };

  const beforeUnloadHandler = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  useEffect(() => {
    if (pauseSpeechButtonRef.current) {
      pauseSpeechButtonRef.current.addEventListener("click", cancelTTS);
    }

    return () => {
      if (pauseSpeechButtonRef.current) {
        pauseSpeechButtonRef.current.removeEventListener("click", cancelTTS);
      }
    };
  }, []);

  // const recognitionRef = useRef(null);

  useEffect(() => {
    const synth = window.speechSynthesis;

    const onVoicesChanged = () => {
      const _voices = synth.getVoices();
      setVoices(_voices);

      _voices.map((item, index) => {
        console.log(index, item);
      });
    };

    onVoicesChanged();

    // Add an event listener for the 'voiceschanged' event
    synth.addEventListener("voiceschanged", onVoicesChanged);

    // Clean up the event listener when the component is unmounted
    return () => {
      synth.removeEventListener("voiceschanged", onVoicesChanged);
      synth.cancel();
    };
  }, []);

  useEffect(() => {
    saveData();
  }, [recordVideoButton, video]);

  function generateRandomFileName(length) {
    const characters = "0123456789abcdefghijklmnopqrstuvwxyz";
    let randomString = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters[randomIndex];
    }

    return randomString;
  }

  async function getDuration(blob) {
    const duration = await getBlobDuration(blob);
    return Math.floor(duration);
  }

  const saveData = async () => {
    if ((!recordVideoButton && chatHistory.length > 0) || video) {
      if (record) {
        console.log("video enabled");
        // let ai_avatar_session_id = null;
        // let conversation_id = null;
        if (video && chatHistory.length > 0) {
          // first save the chat history here
          // const conversationResp = await saveChatHistory(
          //   {
          //     userId: Cookies.get("logged_in_userid"),
          //     chatHistory: chatHistory,
          //     chatTitle: generateChatTitle(chatHistory[0].content, 30),
          //     videoURL: generateRandomFileName(32) + ".mp4",
          //     bucketName: import.meta.env.VITE_S3_BUCKET_NAME,
          //   },
          //   Cookies.get("jwt_access_token")
          // );

          // new approach to save the data
          setUploadProgress(1);
          // const response = await createAvatarConversationSession(fetchedpPtientId, aiAvatarSessionId, conversationId);
          // console.log(
          //   "This is the response from createAvatarConversationSession : ",
          //   response
          // );

          // ai_avatar_session_id = response.data.ai_avatar_session_id;
          // setAiAvatarSessionId(ai_avatar_session_id);
          // conversation_id = response.data.conversation_id;
          
          const ai_avatar_session_id = aiAvatarSessionId;
          const conversation_id = conversationId;
          console.log("ai_avatar_session_id in saveData", ai_avatar_session_id)
          console.log("conversation_id in saveData", conversation_id)

          const filekey = `videos/${fetchedpPtientId}/${conversation_id}/${ai_avatar_session_id}.mp4`;

          // get the pre signed url first
          // const fileName = `videos/${fetchedpPtientId}/${"conversation_id"}/${generateRandomFileName(
          //   32
          // )}.mp4`;

          const videoDuration = await getDuration(video);

          const videoEndTime = Math.floor(new Date().getTime() / 1000);
          const videoStartTime = Math.floor(videoEndTime - videoDuration);

          console.log("File key is: ", filekey);
          console.log("Video Duration: ", videoDuration);
          console.log("Video Start Time: ", videoStartTime);

          // const body = {
          //   fileName: fileName,
          //   bucketName: import.meta.env.VITE_S3_BUCKET_NAME,
          //   upExpire: 1200,
          //   downExpire: 1800,
          // };

          const uploadUrl = await getPresignedUrlForAvatarSessionVideo(
            fetchedpPtientId,
            filekey,
            "upload"
          );

          console.log("The upload links is: ", uploadUrl.data);

          window.addEventListener("beforeunload", beforeUnloadHandler);
          axios
            .put(uploadUrl.data, video, {
              headers: {
                "Content-Type": "video/mp4",
              },
              onUploadProgress: (progressEvent) => {
                const progressPercent = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                const adjustedProgressPercent = progressPercent < 100 ? progressPercent + 1 : progressPercent;
                setUploadProgress(adjustedProgressPercent);
                console.log(`Upload Progress: ${progressPercent}%`);
              },
            })
            .then(() => {
              console.log("File uploaded successfully");
              // setUploadProgress(0);
              dispatch(setVideo(null));
              // then update the video url here
              // test this portion again
              saveAvatarSession(
                ai_avatar_session_id,
                conversation_id,
                videoStartTime,
                videoEndTime,
                fetchedTherapyId,
                fetchedpPtientId,
                filekey,
                chatHistory,
                assessmentId
              ).then((resp) => {
                console.log("The saved response is: ", resp);
                onChatHistorySaveFinish();
                setChatHistory([]);
                dispatch(setVideo(null));
                setChats([]);
                window.removeEventListener("beforeunload", beforeUnloadHandler);
              });
            })
            .catch((err) => {
              console.log("Some error have occured: ", err);
              setUploadProgress(0);
              onChatHistorySaveFinish();
              setChatHistory([]);
              dispatch(setVideo(null));
              setChats([]);
              window.removeEventListener("beforeunload", beforeUnloadHandler);
            });
        }
      } else if (chatHistory.length > 0) {

        console.log("video disabled but chatHistory is available");
        saveChatHistory(
          {
            userId: Cookies.get("logged_in_userid"),
            chatHistory: chatHistory,
            chatTitle: generateChatTitle(chatHistory[0].content, 30),
            videoURL: generateRandomFileName(32),
            fileName: generateRandomFileName(32),
            bucketName: import.meta.env.VITE_S3_BUCKET_NAME,
          },
          Cookies.get("jwt_access_token")
        ).then((resp) => {
          console.log("The saved response is: ", resp);
          onChatHistorySaveFinish();
          setChatHistory([]);
          dispatch(setVideo(null));
          setChats([
            // {
            //   role: "system",
            //   content: systemPrompt,
            // },
          ]);
        });
      }
    }
  };

  const cancelTTS = () => {
    window.speechSynthesis.cancel();
    clearTimeout(myTimeout);
    dispatch(setIsSpeech(false));
  };

  const decisionTree = async (question) => {
    if (question === undefined || question === null || question === "") {
      return "I didn't catch that. Could you please repeat what you said?";
    }
    // truncate the chat list before sending it to the open ai API if threshold is reached
    if (chats.length >= REMEMBER_LENGTH * 2 + 1) {
      deleteChats(1, 2); // delete a set of chat from the sendable object
    }

    let aggregated = aggregateMessage(
      "user",
      "USER: " + question + "\nCOMPANION: ",
      setChats,
      false
    ); //aggregate to the sendable body
    aggregateMessage("user", question, setChatHistory, true); //aggregate to the total history
    console.log("before sending: ", aggregated);

    const gptResult = await getLlmResponseForAvatar(aggregated, aiAvatarSessionId);

    console.log("GPT response: ", gptResult);

    aggregateMessage("assistant", gptResult.data, setChats, false); // aggregate to the sendable body

    // aggregate to the total history
    aggregateMessage("assistant", gptResult.data, setChatHistory, true);
    console.log("Total History Till Now: ", chatHistory);

    onResult("");
    return gptResult.data;
  };

  const onPitchChange = (newValue) => {
    setPitch(newValue);
  };

  const onSpeedChange = (newValue) => {
    setSpeed(newValue);
  };

  const utterWithAvatar = (sentence) => {
    onResponse(sentence);
    const utterance = new SpeechSynthesisUtterance(sentence);
    // check if preferred voice exists
    let _selectedVoice = null;
    for (let i = 0; i < voices.length; i++) {
      if (voices[i].name === selectedSynth) {
        _selectedVoice = voices[i];
        break;
      }
    }

    console.log("language", language);

    if (_selectedVoice === null) {
      for (let i = 0; i < voices.length; i++) {
        if (
          voices[i].lang.includes(language) &&
          voices[i].name === PREFERRED_VOICES[voices[i].lang]
        ) {
          _selectedVoice = voices[i];
          break;
        }
      }
    }
    // if any default voice is needed, set here
    if (_selectedVoice === null) {
      for (let i = 0; i < voices.length; i++) {
        if (voices[i].name === "Google UK English Female") {
          _selectedVoice = voices[i];
          break;
        }
      }
    }

    utterance.voice = _selectedVoice;
    console.log("Selected Voice is: ", _selectedVoice);
    // console.log("The selected voice is: ", selectedVoice);
    // console.log("The full list is : ", voices);

    // utterance.voice = voices[7]; // 10=japanese, 7=hindi, 1=us english(default)
    utterance.pitch = pitch;
    utterance.rate = speed;
    // utterance.volume = 1;

    console.log("The speech is: ", utterance);
    window.speechSynthesis.cancel();
    // the browser speech synthesis api fails after talking for 10sec. reset the api every 10sec
    !detectMobile() && (myTimeout = setTimeout(myTimer, 10000));
    utterance.onend = function () {
      clearTimeout(myTimeout);
    };
    window.speechSynthesis.speak(utterance);

    utterance.addEventListener("start", (event) => {
      onResult(""); // reset the transcript value to remove it from the screen
      console.log(`Utterance has Started`);
      console.log("The event is: ", event);
      dispatch(setIsSpeech(true));
    });

    utterance.addEventListener("end", (event) => {
      dispatch(setIsSpeech(false));
      console.log(
        `Utterance has finished being spoken after ${event.elapsedTime} seconds.`
      );
      console.log("Context: ", isSpeech);
      // handleButtonClick();
    });

    utterance.onerror = (event) => {
      console.error(
        `An error has occurred with the speech synthesis: ${event.error}`,
      );
    };
  };


  const handleButtonClickWithChatMode = async () => {
    onResponse("");
    setButtonState(true);
    const gptResponse = await decisionTree(userTranscript);
    setButtonState(false);
    utterWithAvatar(gptResponse);
  }

  const handleButtonClick = async () => {

    if (conversationMode === 1) {
      handleButtonClickWithChatMode()
      return
    }
    if (isSpeech) return;

    if (record) startIdleCount(); // restart idle counter only if video is allowed

    if (button) {
      // recognitionRef.current.stop();
      console.log("--------------- stop listening -----------------")
      const _transcript = transcript;

      resetTranscript();
      await SpeechRecognition.stopListening();

      setButtonState(false);
      dispatch(setPushToTalkButton(false));
      const gptResponse = await decisionTree(_transcript);
      utterWithAvatar(gptResponse);
      return;
    }

    onResponse("");
    setButtonState(true);
    dispatch(setPushToTalkButton(true));

    // recognitionRef.current = new window.webkitSpeechRecognition();
    // recognitionRef.current.continuous = true;
    // recognitionRef.current.interimResults = true;
    // recognitionRef.current.lang = language;

    // recognitionRef.current.onspeechend = () => {
    //   console.log("recognitionRef.current ended");
    // };

    let _transcript;
    // recognitionRef.current.addEventListener("result", (e) => {
    //   const transcript = Array.from(e.results)
    //     .map((result) => result[0])
    //     .map((result) => result.transcript)
    //     .join("");

    //   _transcript = transcript;
    //   onResult(transcript);
    //   // setTranscript(transcript);
    //   console.log(transcript);
    // });

    // recognitionRef.current.addEventListener("end", async () => {
    //   setButtonState(false);
    //   dispatch(setPushToTalkButton(false));
    //   // https://stackoverflow.com/questions/21947730/chrome-speech-synthesis-with-longer-texts
    //   // https://edvins.io/react-text-to-speech   -> how to change the voice, speed and pitch
    //   const gptResponse = await decisionTree(_transcript);
    //   utterWithAvatar(gptResponse);
    // });

    // recognitionRef.current.addEventListener("audiostart", () => {
    //   console.log("SOUND RECEIVING START");
    // });

    // recognitionRef.current.addEventListener("speechend", () => {
    //   console.log("SPEECH SYNTHESIS STARTED");
    // });

    // recognitionRef.current.start();
    console.log("--------------------- starting to listen ------------------------")
    resetTranscript();
    SpeechRecognition.startListening({
      continuous: true,
      // language: "en-US",
      language: language,
      interimResults: false,
    });
  };

  return (
    <>
      {(uploadProgress > 0 && uploadProgress < 100) || uploadProgress === 100 ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100dvh",
            width: "100dvw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(5px)",
            zIndex: "999",
          }}
        >
          <div
            style={{
              // height: "100px",
              // width: "600px",
              height: "90%",
              width: "80%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "white",
              borderRadius: "20px",
              padding: "10px 26px",
              position: "relative",
              boxShadow: "0px 8px 16px 0px #29282D05",
              overflowY: "auto"
            }}
          >
            <img
              style={{
                height: "24px",
                width: "24px",
                position: "absolute",
                top: "20px",
                right: "20px",
                cursor: "pointer",
              }}
              src={close}
              alt="close icons"
              onClick={resetUploadProgress}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <RealtimeAiAssesement aiAvatarSessionId={aiAvatarSessionId} resetUploadProgress={resetUploadProgress}/>
              <p
                style={{
                  fontSize: "14px",
                  color: "#2C3254",
                  fontWeight: "500",
                }}
              >
                Saving Conversation... {uploadProgress + " %"}
              </p>
              <ProgressBar progress={uploadProgress} />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div
      // style={{
      //   position: "absolute",
      //   right: "5%",
      //   bottom: "10%",
      //   zIndex: "99999",
      // }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          <div
            className="push-to-talk"
            style={{
              cursor: "pointer",
              borderRadius: "8px",
              border: "1px solid rgba(44, 50, 84, 0.15)",
              background: button ? "rgba(255, 255, 255, 0.56)" : "#B899F6",
              // pointerEvents: button ? "none" : "",
              // minWidth: "250px",
              display: recordVideoButton ? "" : "none",
            }}
            id="click_to_record"
            onClick={handleButtonClick}
          >
            <p
              style={{
                color: button ? "#2C3254" : "#FFF",
                textAlign: "center",
                // fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "12px",
              }}
            >
              {button ? "Push to Process" : "Push to Talk"}
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          left: "35%",
          bottom: "10%",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
          }}
        ></div>
      </div>
    </>
  );
};

export default VoiceToTextConverter;
