import { useState, useRef, useCallback, useEffect, useContext } from "react";
import Webcam from "react-webcam";
import style from "./RecordVideo.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setVideo } from "../../../../../store/avatar/avatarSlice";
import { detectMobile } from "../../utils/utils";

const RecordVideo = ({
  record,
  setRecord,
  permission,
  conversationMode,
  startSession,
  startIdleCount,
}) => {
  const dispatch = useDispatch();
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [recordedChunks, setRecordedChunks] = useState([]);

  const recordVideoButton = useSelector(
    (store) => store.avatar.recordVideoButton
  );

  useEffect(() => {
    handleDownload();
  }, [recordedChunks]);

  const handleStartCaptureClick = useCallback(() => {
    console.log("Starting Record");
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = useCallback(() => {
    console.log("Stopping Record");
    mediaRecorderRef.current.stop();
  }, [mediaRecorderRef, webcamRef]);

  const handleDownload = useCallback(() => {
    console.log("Downloading File");
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/mp4",
      });
      dispatch(setVideo(blob));

      // code to download locally
      // const url = URL.createObjectURL(blob);
      // console.log(url);
      // const a = document.createElement("a");
      // document.body.appendChild(a);
      // a.style = "display: none";
      // a.href = url;
      // a.download = "react-webcam-stream-capture.mp4";
      // a.click();
      // window.URL.revokeObjectURL(url);

      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  return (
    conversationMode === 0 &&  permission && (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          contain: 'strict'
        }}
      >
        {recordVideoButton || recordVideoButton ? (
          <Webcam
            className={`${style.cameraView}`}
            audio={!detectMobile()}
            ref={webcamRef}
            muted={true}
            // style={{
            //   display: "flex",
            //   gap: "10px",
            // }}
            onUserMedia={() => {
              console.log("Camera is mounted and ready.");
              handleStartCaptureClick();
            }}
          />
        ) : (
          // <div
          //   style={{
          //     display: "flex",
          //     gap: "10px",
          //     cursor: "pointer",
          //   }}
          //   onClick={() => {
          //     setStartConversation(!startConversation);
          //     setVideo(null);
          //     if (permission) {
          //       startSession();
          //       startIdleCount();
          //     }
          //   }}
          // >
          //   <div className={`${style.recordButton}`}>Start my Conversation</div>
          // </div>
          <div
            style={{
              height: "100%",
              width: "100%",
              border: "1px solid #EAEAEA",
              background: "#EAEAEA",
            }}
          ></div>
        )}
      </div>
    )
  );
};

export default RecordVideo;
