// Export important config IDs or URLs here
const config = {
  // API_URL: "http://localhost:2626",
  API_URL: "https://fjdv8n9jaf.execute-api.us-west-2.amazonaws.com",
  GOOGLE_API_KEY: "AIzaSyBWBKEmQaP5gUphJc8pa1P-SEjDjgtjw3A",
  GOOGLE_CLIENT_ID:
    "817832900797-m6ouvvag42jtr29aama28trfui7dcq0h.apps.googleusercontent.com",
};

export default config;
